import { render, staticRenderFns } from "./AreaAdminSet.vue?vue&type=template&id=652fc464&scoped=true&"
import script from "./AreaAdminSet.vue?vue&type=script&lang=js&"
export * from "./AreaAdminSet.vue?vue&type=script&lang=js&"
import style0 from "./AreaAdminSet.vue?vue&type=style&index=0&id=652fc464&prod&lang=less&scoped=true&"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "652fc464",
  null
  
)

export default component.exports