<template>
  <el-container>
    <left-menu :menuData="allLeftMenus" v-if="allLeftMenus.length>0"></left-menu>
    <el-container>
      <el-header>
        <top-menu :topLeftMenu="topLeftMenus" :topRightMenu="this.topRightMenus" :isViewPoint="allLeftMenus.length>0"></top-menu>
      </el-header>
      <el-main id="adminMain">
        <router-view></router-view>
      </el-main>
    </el-container>
  </el-container>
</template>
<style lang="less" scoped>
.el-header {
  padding: 0;
}
</style>
<script>
import { getAllMenus } from '@/api/wbkj/MainMenu'; //数据源,如果没有使用,为了美观请删除
import LeftMenu from '@/components/wbkj/LeftMenu.vue'
import TopMenu from '@/components/wbkj/TopMenu.vue'
export default {
  data () {
    return {
      allRoute: {},
      allMenus: [],
      allLeftMenus: [],
      topLeftMenus: [],
      topRightMenus: []
    }
  },
  components: { LeftMenu, TopMenu },
  methods: {
    doMenuBody (menuBody) {
      for (let i = 0; i < menuBody.length; i++) {
        let item = menuBody[i];
        if (item.isNode) {
          this.doMenuBody(item.bodyItems);
        }
        else if (item.linkType == 1) {
          this.$set(this.allRoute, item.routeIndex, { id: item.linkCusPanelId, directName: item.viewName, routeIndex: item.routeIndex })
        }
        else if (item.linkType == 2) {
          this.$set(this.allRoute, item.routeIndex + '_' + item.linkDocPanelId, { id: item.linkDocPanelId, directName: item.viewName, routeIndex: item.routeIndex })
        }
      }
    },
  },
  watch: { //这里是由于路由路径变化,回退或前进引起的权限变化,所以要监听路径是否与activeCus的名称相同,主要是为了防止回退的现现发生
    '$route.path': {
      handler: function () { //newValue, oldValue
        //console.log("old:" + oldValue);
        // console.log("new:" + newValue);
        let routeIndex = JSON.parse(localStorage.getItem("activeCus")).routeIndex;
        //console.log("activeCusIndex:" + routeIndex);
        //console.log("routeName:" + this.$route.name);

        if (this.$route.name == "docInfo") {
          console.log("请求是Doc")
          let routePath = JSON.parse(localStorage.getItem("activeCus")).routePath;
          if (this.$route.path != routePath) {
            // console.log(this.$route.params.id)
            // console.log("路径还不同");
            if (this.allRoute[this.$route.name + "_" + this.$route.params.id]) {
              this.$store.commit('setActiveCus', this.allRoute[this.$route.name + "_" + this.$route.params.id]); //存到Store里,store已经存在cookie里了,万一刷新页面不丢
            }
          }
        }
        else if (this.$route.name != routeIndex) {
          if (this.allRoute[this.$route.name]) {
            this.$store.commit('setActiveCus', this.allRoute[this.$route.name]); //存到Store里,store已经存在cookie里了,万一刷新页面不丢
          }
          //console.log("请求不相同")
        }
        else {
          // console.log("请求相同")
        }
      }
    }
    //deep: true,
    //immediate: true//立即执行
  },
  mounted () {
    getAllMenus().then(response => {
      if (response.data.success) {
        this.allMenus = response.data.data;
        this.allRoute = {};
        for (let i = 0; i < this.allMenus.length; i++) {
          let item = this.allMenus[i];
          if (item.position == 'left') {
            this.allLeftMenus.push(item);
          }
          else if (item.position == 'top-left') {
            this.topLeftMenus.push(item);

          } else if (item.position == 'top-right') {
            this.topRightMenus.push(item);
          }
          //以下是处理一个路由表的反向查询
          if (item.isNode) {
            this.doMenuBody(item.bodyItems);
          }
          else if (item.linkType == 1) {
            this.$set(this.allRoute, item.routeIndex, { id: item.linkCusPanelId, directName: item.viewName, routeIndex: item.routeIndex })
          }
          else if (item.linkType == 2) {
            this.$set(this.allRoute, item.routeIndex + '_' + item.linkDocPanelId, { id: item.linkDocPanelId, directName: item.viewName, routeIndex: item.routeIndex })
          }
        }
        console.log(this.allRoute);
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
  }
}
</script >