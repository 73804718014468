/*特殊事件-出错跟踪 */
import store from '@/store';
export function notifyInfo (info) { this.$notify.info({ title: '提示', message: info }); }
export function notifySuccess (success) { this.$notify.success({ title: '成功', message: success }); }
export function notifyWarning (warning) { this.$notify.warning({ title: '警告', message: warning }); }
export function notifyError (error) { this.$notify.error({ title: '错误', message: error }); }

/*特殊事件-下载文件 */
export function downloadFile (downUrl, downName) {
  let a = document.createElement('a')
  a.href = downUrl + '?downToken=' + store.getters.getToken();
  if (downName == null || downName == '') {
    downName = generateRandomString(16);
  }
  a.download = downName;
  a.target = '_blank'//在新窗口打开
  a.click();
}

function generateRandomString (length) {
  const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789_';
  let result = '';
  for (let i = 0; i < length; i++) {
    result += characters.charAt(Math.floor(Math.random() * characters.length));
  }
  return result;
}
/*计算百分比 */
export function countPercentage (ele, ments, trueValue) {
  if (ments && ele) {
    let value = parseFloat((ele * 100 / ments).toFixed(2) - 0);
    if (value > 100) {
      if (trueValue) {
        return value;
      }
      else {
        return 100;
      }

    }
    else {
      return value;
    }
  }
  else {
    return 0;
  }
}
/*计算金额的大小 Filter里也同时存在numFilter,目的是为了两都共存不用改代码 */
export function numView (value) {
  if (value) {
    if (Math.abs(parseFloat(value / 1000)).toFixed(2) > 5) {
      return parseFloat(value / 10000).toFixed(2) + '万'
    }
    else {
      return parseFloat(value).toFixed(2);
    }
  }
  else {
    return "0.00";
  }
}
/*权限计算 */
export function checkRight (direcParam, rightName) {

  if (direcParam == null) {
    return false;
  }
  if (direcParam.id == null) {
    return false;
  }
  let rightKey = direcParam.id + "_" + rightName;
  let allow = direcParam.allow[rightKey];
  if (allow == null) {
    return false;
  }
  else if (allow == '1') {
    console.log(direcParam);
    console.log(rightName);
    return true;
  }
  else {
    return false;
  }
}

/*自定义模块权限计算, */
export function checkCusRight (id, rightName) {
  const cusRight = store.getters.getLoginUser().cusRight;
  const rightKey = id + "_" + rightName;
  const allow = cusRight[rightKey];
  if (allow == null) {
    return false;
  }
  else if (allow == '1') {
    return true;
  }
  else {
    return false;
  }
}
/*自定义模块权限计算, */
export function checkDocRight (id, rightName) {
  const docRight = store.getters.getLoginUser().docRight;
  const rightKey = id + "_" + rightName;
  const allow = docRight[rightKey];
  if (allow == null) {
    return false;
  }
  else if (allow == '1') {
    return true;
  }
  else {
    return false;
  }
}
export function formatTooltipContent (content) {
  // 将\n替换为<br>以便在HTML中正确换行
  return content.replace(/\n/g, '<br>');
}