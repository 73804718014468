<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="19">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ name: this.$store.getters.getLoginUser().home }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="5">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" style="width: 100%;" @change="tableChangeSearchKey" clearable>
            <el-button type="primary" slot="append"><i class="fa fa-search" style="color:#409EFF"></i></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row v-if="checkCusRight(direcParam.id,'new')||checkCusRight(direcParam.id,'modify')||checkCusRight(direcParam.id,'delete')">
      <el-button-group>
        <el-button @click="formDataNew" v-if="checkCusRight(direcParam.id,'new')" type="primary">新建</el-button>
        <el-button @click="formDataModify" v-if="checkCusRight(direcParam.id,'modify')" type="primary">修改</el-button>
        <el-button @click="formDataDelete" v-if="checkCusRight(direcParam.id,'delete')" type="primary">删除</el-button>
      </el-button-group>
    </el-row>
    <el-table ref="companyTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" type="index" width="40" label="序"></el-table-column>
      <el-table-column header-align="center" prop="cmpCnName" label="公司名称" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.cmpCnName)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="cmpShortName" label="简称" width="60" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.cmpShortName)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="cmpRegAddress" label="注册地址" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.cmpRegAddress)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="cmpAccounts" label="统一社会信用号" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.cmpAccounts)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="cmpManager" label="法人" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.cmpManager)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="linkMan" label="常用联系人" show-overflow-tooltip>
        <template slot-scope="scope">
          <span :key="index" v-for="(item,index) in scope.row.linkMan" style="margin-right:5px;">
            <el-tooltip placement="top">
              <div slot="content" style="line-height:20px;">姓名:{{item.name}}<br />类型:{{item.type}}<br />手机:{{item.mobile}}<br /></div>
              <el-tag>{{item.name}}</el-tag>
            </el-tooltip>
          </span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="bankInfo" label="开户银行">
        <template slot-scope="scope">
          <span :key="index" v-for="(item,index) in scope.row.bankInfo" style="margin-right:5px;">
            <el-tooltip placement="top">
              <div slot="content" style="line-height:20px;">开户行:{{item.bank}}<br />开户名称:{{item.name}}<br />账号:{{item.code}}<br />备注:{{item.note}}<br /></div>
              <el-tag>{{item.bank}}</el-tag>
            </el-tooltip>
          </span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" prop="invoiceInfo" label="开票信息" show-overflow-tooltip>
        <template slot-scope="scope">
          <span :key="index" v-for="(item,index) in scope.row.invoiceInfo" style="margin-right:5px;">
            <el-tooltip placement="top">
              <div slot="content" style="line-height:20px">税号:{{item.invoice}}<br />名称:{{item.name}}<br />类型:{{item.type}}<br />备注:{{item.note}}<br /></div>
              <el-tag>{{item.type}}</el-tag>
            </el-tooltip>
          </span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="附件">
        <template slot-scope="scope">
          <span v-for="(item,index) in scope.row.addFile" :key="index" style="padding-left: 4px;">
            <el-link type="primary" :href="item.serverPath" target="_blank" :download="item.fileName" :underline="false">
              <el-tooltip class="item" effect="light" :content="`点击下载:${item.fileName}.${item.extName}`" placement="top-start">
                <i class="el-icon-paperclip" style="font-size:20px;"></i>
              </el-tooltip>
            </el-link>
          </span>
        </template>
      </el-table-column>
      <el-table-column prop="isPublic" label="状态" width="100">
        <template slot-scope="scope">
          <span style="padding-left: 4px;" v-if="scope.row.isPublic">
            <el-link :underline="false">
              <el-tooltip class="item" effect="light" content="正在使用" placement="top-start">
                <i class="el-icon-folder-checked" style="font-size:16px;color: #409EFF;font-weight: 400;"></i>
              </el-tooltip>
            </el-link>
          </span>
          <span style="padding-left: 4px;" v-else>
            <el-link :underline="false">
              <el-tooltip class="item" effect="light" content="未启用" placement="top-start">
                <i class="el-icon-edit-outline" style="font-size:16px;color: #999999;font-weight: 400;"></i>
              </el-tooltip>
            </el-link>
          </span>
          <span style="padding-left: 4px;" v-if="scope.row.isLink">
            <el-link>
              <el-tooltip class="item" effect="light" content="关联公司" placement="top-start">
                <i class="el-icon-coordinate" style="font-size:16px;color: #f56c6c;font-weight: 400;"></i>
              </el-tooltip>
            </el-link>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>

    <!--编辑的表单-->

    <el-dialog v-dialogDrag :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width" :top="formDialogParam.top"
      :close-on-click-modal='false'>
      <el-form ref="companyFormRef" :model="formData" :rules="formDialogRules" label-width="90px" size="small"><!-- `prop` 为绑定的规则 -->

        <el-row :gutter="20">
          <el-col :span="12">
            <el-form-item label="公司名称" prop="cmpCnName">
              <el-input v-model="formData.cmpCnName" placeholder="请输入公司名称"></el-input>
            </el-form-item>
            <el-form-item label="注册地址" prop="cmpRegAddress">
              <el-input v-model="formData.cmpRegAddress" placeholder="请输入注册地址"></el-input>
            </el-form-item>
            <el-form-item label="信用号码" prop="cmpAccounts">
              <el-input v-model="formData.cmpAccounts" placeholder="请输入统一社会信用号"></el-input>
            </el-form-item>
            <el-form-item label="主体类型" prop="isLink">
              <el-checkbox v-model="formData.isLink">是关联公司</el-checkbox>
            </el-form-item>
            <el-form-item label="是否启用" prop="isPublic">
              <el-checkbox v-model="formData.isPublic">启用</el-checkbox>
            </el-form-item>
          </el-col>

          <el-col :span="12">
            <el-form-item label="简称" prop="cmpShortName">
              <el-input v-model="formData.cmpShortName" placeholder="请输入简称"></el-input>
            </el-form-item>
            <el-form-item label="通讯地址" prop="cmpAddress">
              <el-input v-model="formData.cmpAddress" placeholder="请输入通讯地址"></el-input>
            </el-form-item>
            <el-form-item label="法人" prop="cmpManager">
              <el-input v-model="formData.cmpManager" placeholder="请输入法人"></el-input>
            </el-form-item>
            <el-form-item label="备注" prop="cmpNote">
              <el-input v-model="formData.cmpNote" type="textarea" :rows="3" resize="none" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="常用联系人" prop="linkMan">
          <span :key="index" v-for="(item,index) in formData.linkMan" style="margin-right:5px;">
            <el-tooltip placement="top">
              <div slot="content" style="line-height:20px">姓名:{{item.name}}<br />类型:{{item.type}}<br />手机:{{item.mobile}}<br /></div>
              <el-tag @close="linkManDelete(item)" closable :disable-transitions="false">{{item.name}}</el-tag>
            </el-tooltip>
          </span>
          <el-button size="small" type="success" @click="linkManNew()">+</el-button>
        </el-form-item>
        <el-dialog v-dialogDrag :title="linkManDialogParam.title" :visible.sync="linkManDialogParam.visible" width="20%" :close-on-click-modal='false'
          append-to-body>
          <el-form :model="linkManData" label-width="60px" size="small">
            <el-form-item label="姓名">
              <el-input v-model="linkManData.name" placeholder="请输入姓名"></el-input>
            </el-form-item>
            <el-form-item label="类型">
              <el-input v-model="linkManData.type" placeholder="请输入类型(例如:财务联系人)"></el-input>
            </el-form-item>
            <el-form-item label="手机">
              <el-input v-model="linkManData.mobile" placeholder="请输入手机号码"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer">
            <el-button @click="linkManDialogParam.visible = false">取 消</el-button>
            <el-button type="primary" @click="linkManPush()">确 定</el-button>
          </span>
        </el-dialog>

        <el-form-item label="开户银行" prop="bankInfo">
          <span :key="index" v-for="(item,index) in formData.bankInfo" style="margin-right:5px;">
            <el-tooltip placement="top">
              <div slot="content" style="line-height:20px">开户行:{{item.bank}}<br />开户名称:{{item.name}}<br />账号:{{item.code}}<br />备注:{{item.note}}<br /></div>
              <el-tag @close="bankInfoDelete(item)" closable :disable-transitions="false">{{item.bank}}</el-tag>
            </el-tooltip>
          </span>
          <el-button size="small" type="success" @click="bankInfoNew()">+</el-button>
        </el-form-item>
        <el-dialog v-dialogDrag :title="bankInfoDialogParam.title" :visible.sync="bankInfoDialogParam.visible" width="20%" :close-on-click-modal='false'
          append-to-body>
          <el-form :model="bankInfoData" label-width="80px" size="small">

            <el-form-item label="开户行">
              <el-input v-model="bankInfoData.bank" placeholder="请输入开户行"></el-input>
            </el-form-item>
            <el-form-item label="开户名称">
              <el-input v-model="bankInfoData.name" placeholder="请输入开户名称"></el-input>
            </el-form-item>
            <el-form-item label="账号">
              <el-input v-model="bankInfoData.code" placeholder="请输入类型账号"></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="bankInfoData.note" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer">
            <el-button @click="bankInfoDialogParam.visible = false">取 消</el-button>
            <el-button type="primary" @click="bankInfoPush()">确 定</el-button>
          </span>
        </el-dialog>

        <el-form-item label="开票信息" prop="invoiceInfo">
          <span :key="index" v-for="(item,index) in formData.invoiceInfo" style="margin-right:5px;">
            <el-tooltip placement="top">
              <div slot="content" style="line-height:20px">税号:{{item.invoice}}<br />名称:{{item.name}}<br />类型:{{item.type}}<br />备注:{{item.note}}<br /></div>
              <el-tag @close="invoiceInfoDelete(item)" closable :disable-transitions="false">{{item.type}}</el-tag>
            </el-tooltip>
          </span>
          <el-button size="small" type="success" @click="invoiceInfoNew()">+</el-button>
        </el-form-item>
        <el-dialog v-dialogDrag :title="invoiceInfoDialogParam.title" :visible.sync="invoiceInfoDialogParam.visible" width="20%" :close-on-click-modal='false'
          append-to-body>
          <el-form :model="invoiceInfoData" label-width="60px" size="small">
            <el-form-item label="税号">
              <el-input v-model="invoiceInfoData.invoice" placeholder="请输入税号"></el-input>
            </el-form-item>
            <el-form-item label="名称">
              <el-input v-model="invoiceInfoData.name" placeholder="请输入名称"></el-input>
            </el-form-item>
            <el-form-item label="类型">
              <el-input v-model="invoiceInfoData.type" placeholder="请输入类型(普票,增值税)"></el-input>
            </el-form-item>
            <el-form-item label="备注">
              <el-input v-model="invoiceInfoData.note" placeholder="请输入备注"></el-input>
            </el-form-item>
          </el-form>
          <span slot="footer">
            <el-button @click="invoiceInfoDialogParam.visible = false">取 消</el-button>
            <el-button type="primary" @click="invoiceInfoPush()">确 定</el-button>
          </span>
        </el-dialog>
        <el-form-item label="附件" prop="addFile">
          <div>营业执照、资质证明等相关材料</div>
          <el-upload action="#" ref="addFileUpload" list-type="picture-card" :http-request="addFileSubmit" :on-error="notifyError" :on-success="addFileSuccess"
            :file-list="formData.addFile">
            <i slot="default" class="el-icon-plus"></i>
            <div slot="file" slot-scope="{file}">
              <el-image class="el-upload-list__item-thumbnail" :src="file.viewIcon" fit="fill" alt="" v-if="file.status=='success'"></el-image>
              <el-progress v-if="addFileProgressParam.visible&&file.status!='success'" type="circle" :width="90"
                :percentage="addFileProgressParam.percentage"></el-progress>
              <div class="el-upload-list__item-name" v-if="file.status=='success'">
                {{file.fileName }}
              </div>
              <div class="el-upload-list__item-size" v-if="file.status=='success'">
                {{file.size | numFilter}}
              </div>
              <span class="el-upload-list__item-actions" v-if="!addFileProgressParam.visible">
                <el-tooltip class="item" effect="dark" :content="`下载:${file.fileName}`" placement="top-start">
                  <span class="el-upload-list__item-delete" @click="downloadFile(file.serverPath,file.fileName)">
                    <i class="el-icon-download"></i>
                  </span>
                </el-tooltip>
                <span class="el-upload-list__item-delete" @click="addFileRemove(file)">
                  <i class="el-icon-delete"></i>
                </span>
              </span>
            </div>
          </el-upload>
        </el-form-item>
      </el-form>
      <span slot="footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="30%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column prop="cmpCnName" label="选定的记录">
        </el-table-column>
      </el-table>
      <span slot="footer">
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script> import store from '@/store';
import { getTableData, postFile, doNew, doModify, doDelete, } from '@/api/wbkj/Company'; //数据源,如果没有上传文件的部分,请删除,不删除也不影响
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  data () {
    return {
      /*导航栏状态 */
      direcParam: {
        'directName': '公司管理'
      },
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'tableKey': 0,
        'searchType': 'normal'
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '60%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: ''
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        cmpCnName: '',
        cmpShortName: '',
        cmpRegAddress: '',
        cmpAddress: '',
        cmpAccounts: '',
        cmpManager: '',
        linkMan: [],
        bankInfo: [],
        invoiceInfo: [],
        cmpNote: '',
        addFile: [],
        isLink: false,
        isPublic: false,

      },
      formDialogRules:
      {
        cmpCnName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cmpShortName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cmpRegAddress: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cmpAddress: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cmpAccounts: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        cmpManager: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isLink: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isPublic: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
      /*-------------------------------------------------json linkMan 参数-----------------------------------------------*/
      linkManDialogParam: {
        visible: false,
        title: '新建联系人',
        width: '30%',
        top: '15vh',
      },
      linkManRawData: {
        name: '',
        type: '',
        mobile: '',

      },


      linkManData: {
        name: '',
        type: '',
        mobile: '',
      },
      /*-------------------------------------------------json bankInfo 参数-----------------------------------------------*/
      bankInfoDialogParam: {
        visible: false,
        title: '新建银行信息',
        width: '30%',
        top: '15vh',
      },
      bankInfoRawData: {
        bank: '',
        name: '',
        code: '',
        note: '',

      },
      bankInfoData: {
        bank: '',
        name: '',
        code: '',
        note: '',
      },
      /*-------------------------------------------------json invoiceInfo 参数-----------------------------------------------*/
      invoiceInfoDialogParam: {
        visible: false,
        title: '新建开票信息',
        width: '30%',
        top: '15vh',
      },
      invoiceInfoRawData: {
        invoice: '',
        name: '',
        type: '',
        note: '',

      },
      invoiceInfoData: {
        invoice: '',
        name: '',
        type: '',
        note: '',
      },

      /*-------------------------------------------------json addFile上传 start-----------------------------------------------*/
      /*json addFile上传的进度条状态 */
      addFileProgressParam: {
        visible: false,
        percentage: 0
      },

    }
  },
  methods: {
    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定的记录(已发布的不删除)"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    /*表单(form)-发布示例 */
    /*formDataPublic () {
        if (this.tableMultipleSelection.length < 1) {
            this.notifyInfo("您必须最少选择一条记录,才能发布");
        }
        else {
            this.formChooseDialogParam.title = "将选定的记录,发布/取消发布"
            this.formChooseDialogParam.formChooseAction = 'public';
            this.formChooseDialogParam.action1 = true;
            this.formChooseDialogParam.action1View = '取消发布';
            this.formChooseDialogParam.action2 = true;
            this.formChooseDialogParam.action2View = '发 布';
            this.formChooseDialogParam.visible = true;
        }
    },*/
    formDataValidate () {
      let result = true;
      this.$refs.companyFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.formData));
      postData.linkMan = JSON.stringify(this.formData.linkMan);//由于MysqlJson的原因
      postData.bankInfo = JSON.stringify(this.formData.bankInfo);//由于MysqlJson的原因
      postData.invoiceInfo = JSON.stringify(this.formData.invoiceInfo);//由于MysqlJson的原因
      postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因

      //postData.classId = this.tableParam.classId;
      //postData.imageUrl = JSON.stringify(this.formData.imageUrl);//由于MysqlJson的原因
      //postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因
      //doNew(this.formData).then(response => {
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    formChooseSubmit () {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      this.$refs.companyTableRef.clearSelection(); //清除选中
      this.$refs.companyTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },
    /*-------------------------------------------------json linkMan 方法-----------------------------------------------*/
    linkManNew () {
      this.linkManData = JSON.parse(JSON.stringify(this.linkManRawData))//重置
      this.linkManDialogParam.visible = true;
    },
    linkManPush () {
      this.formData.linkMan.push(this.linkManData);//插到最后面
      this.linkManDialogParam.visible = false;//不显示
    },
    linkManDelete (key) {
      let index = this.formData.linkMan.indexOf(key);//查出文件在哪
      this.formData.linkMan.splice(index, 1);
    },
    /*JsonArray-linkMan*/

    /*-------------------------------------------------json bankInfo 方法-----------------------------------------------*/
    bankInfoNew () {
      this.bankInfoData = JSON.parse(JSON.stringify(this.bankInfoRawData))//重置
      this.bankInfoData.name = this.formData.cmpCnName;
      this.bankInfoDialogParam.visible = true;
    },
    bankInfoPush () {
      this.formData.bankInfo.push(this.bankInfoData);//插到最后面
      this.bankInfoDialogParam.visible = false;//不显示
    },
    bankInfoDelete (key) {
      let index = this.formData.bankInfo.indexOf(key);//查出文件在哪
      this.formData.bankInfo.splice(index, 1);
    },
    /*JsonArray-bankInfo*/

    /*-------------------------------------------------json invoiceInfo 方法-----------------------------------------------*/
    invoiceInfoNew () {
      this.invoiceInfoData = JSON.parse(JSON.stringify(this.invoiceInfoRawData))//重置
      this.invoiceInfoData.name = this.formData.cmpCnName;
      this.invoiceInfoDialogParam.visible = true;
    },
    invoiceInfoPush () {
      this.formData.invoiceInfo.push(this.invoiceInfoData);//插到最后面
      this.invoiceInfoDialogParam.visible = false;//不显示
    },
    invoiceInfoDelete (key) {
      let index = this.formData.invoiceInfo.indexOf(key);//查出文件在哪
      this.formData.invoiceInfo.splice(index, 1);
    },
    /*JsonArray-invoiceInfo*/


    /*-------------------------------------------------json addFile上传 start-----------------------------------------------*/
    addFileSuccess () { //成功后清除控制条
      this.addFileProgressParam.visible = false; //将进度条清空
      this.addFileProgressParam.percentage = 0;//将进度条百分比置0
    },
    addFileRemove (file) {
      let index = this.formData.addFile.indexOf(file);//查出文件在哪
      this.formData.addFile.splice(index, 1);  // 从数组中添加 / 删除项目，然后返回被删除的项目
    },
    addFileSubmit (param) {
      const addFileData = new FormData()
      addFileData.append('file', param.file);
      addFileData.append('childFile', "company");
      postFile(addFileData, {
        onUploadProgress: (progressEvent) => {
          this.addFileProgressParam.visible = true;
          let percent =
            ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          this.addFileProgressParam.percentage = percent;
        }
      }).then(response => {
        if (response.data.success) {
          const jsonData = response.data.data;
          jsonData.uid = param.file.uid; //没有UID更新时会跳动画
          this.formData.addFile.push(jsonData);//插到最后面
          param.onSuccess();  // 将进度条清空
        }
        else {
          param.onError(response.data.msg);
        }
      })
    },
    /*-------------------------------------------------json addFile上传 end----------------------------------------------*/


  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {

    this.direcParam = JSON.parse(store.getters.getActiveCus());

    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    this.tableDateRefresh();

  }
}
</script>
