import { httpLogin, loginJson } from '@/utils/loginRequest'
//定义一个Links接口,数据表接口
export const adminLogin = (formData) => {
    return httpLogin.post('/adminAction', formData)
}
export const getAdminValidateCode = () => {
    return loginJson.get('/adminCode')
}
export const getSetting = () => {
    return loginJson.get('/settingInfo')
}
