import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个BizPrices接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/bizPrices/list', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/bizPrices/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/bizPrices/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/bizPrices/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/bizPrices/delete', formData)
}


