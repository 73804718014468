<!--上传文件或图片组件-->
<template>
  <el-input v-model="upImg" class="input-with-select" placeholder="请上传文件" :readonly="this.readOnly" @change="changeValue">
    <template slot="append">
      <el-upload action="" :http-request="upImgSubmit" :on-error="notifyError" :show-file-list="false">
        <i class="el-icon-upload" style="font-size: 25px;color:#409EFF"></i>
      </el-upload>
    </template>
  </el-input>
</template>
<script> 
import store from '@/store';
import { postImg } from '@/api/utils/UploadFileOrImg.js'; //数据源,如果没有上传文件的部分,请删除postImg, postImg,不删除也不影响
export default {
  props: {
    inputValue: { type: String, require: true }, //绑定的值
    readOnly: { type: Boolean, require: true }, //是否只读
    childDir: { type: String, require: true }, //创建的目录
  },
  data () {
    return {
      upImg: this.inputValue,
      viewToken: '',
    };
  },
  methods: {
    /*-------------------------------------------------单文件(字段名里带File)上传 -----------------------------------------------*/
    /*上传upImg文件类型判断,默认doc,docx,pdf是都可以上传*/
    changeValue (value) {
      const data = { serverPath: value }
      this.$emit('success', data);
    },
    upImgSubmit (param) {
      const upImgData = new FormData()
      upImgData.append('file', param.file);
      upImgData.append('childFile', this.childDir);
      postImg(upImgData).then(response => {
        if (response.data.success) {
          this.upImg = response.data.data.serverPath;
          this.$emit('success', response.data.data);
          param.onSuccess();  // 上传成功的图片会显示绿色的对勾
        }
        else {
          param.onError(response.data.msg);//会去调用出错的方法
        }
      }).catch(error => {
        param.onError(error); //会去调用出错的解决办法
      })
    },
    /*-------------------------------------------------单文件(字段名里带File)上传 -----------------------------------------------*/
  },
  watch: {
    inputValue: function () {
      this.upImg = this.inputValue;
      this.viewToken = store.getters.getToken();
    }
  },
}
</script>
