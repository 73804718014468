<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span><i class="el-icon-cpu" style="color:#67C23A"></i> 系统信息 </span>
    </div>
    <div class="text item">
      <el-form label-width="80px" size="mini">
        <el-form-item label="操作系统">{{javaInfo.osName}} {{javaInfo.osArch }} {{javaInfo.osVersion}}</el-form-item>
        <el-form-item label="Java版本">{{javaInfo.jvmName}} {{javaInfo.javaVersion}}</el-form-item>
        <!-- <el-form-item label="Java版本">{{javaInfo.javaVersion}} ({{javaInfo.jvmVersion}})</el-form-item> -->
        <el-form-item label="平台路径">JAVA_HOME:{{javaInfo.javaHome}}</el-form-item>
        <el-form-item label="已用内存">
          <el-progress :text-inside="true" :stroke-width="18"
            :percentage="countPercentage(this.javaInfo.jvmTotalMem - this.javaInfo.jvmFreeMem,this.javaInfo.jvmTotalMem)"></el-progress></el-form-item>
      </el-form>
    </div>
  </el-card>
</template>
 <script> 
import { getSys } from '@/api/wbkj/Setting.js';
export default {
  data () {
    return {
      javaInfo: {},
    }
  },
  methods: {},
  mounted () {
    getSys().then(response => {
      if (response.data.success) {
        this.javaInfo = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    })
  },
  watch: {
  }
}
</script>