<!-- 首页  -->
<template>
  <div>
    <div>
      <el-row>
        <el-col :span="24">
          <h3><i class="el-icon-data-analysis" style="color:#67C23A"></i> 项目概况</h3>
        </el-col>
      </el-row>
      <el-row :gutter="20">
        <el-col :span="8">
          <dir-info></dir-info>
        </el-col>
        <el-col :span="8">
          <java-info></java-info>
        </el-col>
        <el-col :span="8">
          <cer-info></cer-info>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script> 
import JavaInfo from '@/components/utils/JavaInfo.vue';
import CerInfo from '@/components/utils/CerInfo.vue';
import DiskInfo from '@/components/utils/DiskInfo.vue';
import DirInfo from '@/components/utils/DirInfo.vue';
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';

export default {
  //import引入的组件需要注入到对象中才能使用
  components: { DiskInfo, CerInfo, JavaInfo, DirInfo },
  data () {
    //这里存放数据
    return {
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},

  //方法集合
  methods: {
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () {
  },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () { },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
