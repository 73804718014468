<!--上传文件或图片组件-->
<template>
  <div>
    <el-upload action="#" ref="jsonImgUpload" list-type="picture-card" :http-request="jsonImgSubmit" :before-upload="jsonImgBeforeUp" :on-error="notifyError"
      :on-success="jsonImgSuccess" :file-list="jsonImg">
      <i slot="default" class="el-icon-plus"></i>
      <div slot="file" slot-scope="{file}">
        <el-image class="el-upload-list__item-thumbnail" :src="file.serverPath" fit="scale-down" alt="" v-if="file.status=='success'"></el-image>
        <el-progress v-if="jsonImgProgressParam.visible&&file.status!='success'" type="circle" :width="50" style="margin-left:45px;"
          :percentage="jsonImgProgressParam.percentage"></el-progress>
        <div class="el-upload-list__item-size" v-if="file.status=='success'">
          {{file.size | sizeFilter}}
        </div>
        <span class="el-upload-list__item-actions" v-if="!jsonImgProgressParam.visible">
          <el-tooltip class="item" effect="dark" content="点击预览" placement="top-start">
            <span class="el-upload-list__item-preview" @click="jsonImgPreview(file)">
              <i class="el-icon-zoom-in"></i>
            </span>
          </el-tooltip>
          <span v-if="!jsonImgDialogParam.disabled" class="el-upload-list__item-delete" @click="jsonImgRemove(file)">
            <i class="el-icon-delete"></i>
          </span>
        </span>
      </div>
    </el-upload>
    <el-dialog v-dialogDrag :visible.sync="jsonImgDialogParam.imageShow" append-to-body>
      <template slot="title">
        <div style="font-size:14px;">图片预览</div>
      </template>
      <img width="100%" :src="jsonImgDialogParam.viewUrl" alt="">
    </el-dialog>
  </div>
</template>
<script> 
import store from '@/store';
import { postImg } from '@/api/utils/UploadFileOrImg.js'; //数据源,如果没有使用,为了美观请删除
export default {
  props: {
    inputArray: { type: Array, require: true }, //绑定的值
    label: { type: String, require: true }, //标题
    childDir: { type: String, require: true }, //创建的目录
  },
  data () {
    return {
      jsonImg: this.inputArray,
      /*json jsonImg上传的进度条状态 */
      jsonImgProgressParam: {
        visible: false,
        percentage: 0
      },
      jsonImgDialogParam: { //图片显示对话框的参数
        viewUrl: '',//图片的URL
        imageShow: false,//是否显示
        disabled: false,//是否禁用按钮(新建的时候无图片所以没有显示)
      },
    };
  },
  methods: {
    /*-------------------------------------------------json jsonImg图片上传 -----------------------------------------------*/
    jsonImgSuccess () { //成功后清除控制条
      this.jsonImgProgressParam.visible = false; //将进度条清空
      this.jsonImgProgressParam.percentage = 0;//将进度条百分比置0
    },
    jsonImgRemove (file) {
      let index = this.jsonImg.indexOf(file);//查出文件在哪
      if (index > -1) {
        this.jsonImg.splice(index, 1);
      }
      return true;
      // 从数组中添加 / 删除项目，然后返回被删除的项目
    },
    jsonImgPreview (file) {
      this.jsonImgDialogParam.viewUrl = file.serverPath + '?downToken=' + store.getters.getToken();
      this.jsonImgDialogParam.imageShow = true;
    },
    jsonImgBeforeUp (file) {
      const fileType = file.name.substring(file.name.lastIndexOf('.'))
      if (fileType.toLowerCase() != '.jpg' && fileType.toLowerCase() != '.png' && fileType.toLowerCase() != '.gif') {
        this.notifyWarning("不支持该扩展名的文件,请上传扩展名为png,jpg,gif的图片文件");
        return false;
      }
      else {
        return true;
      }
    },
    jsonImgSubmit (param) {
      const jsonImgData = new FormData()
      jsonImgData.append('file', param.file);
      jsonImgData.append('childFile', this.childDir);
      postImg(jsonImgData, {
        onUploadProgress: (progressEvent) => {
          this.jsonImgProgressParam.visible = true;
          let percent =
            ((progressEvent.loaded / progressEvent.total) * 100) | 0;
          this.jsonImgProgressParam.percentage = percent;
        }
      }).then(response => {
        if (response.data.success) {
          const jsonData = response.data.data;
          jsonData.uid = param.file.uid;
          this.jsonImg.unshift(jsonData);//从前插入数据
          param.onSuccess(); // 上传成功的图片会显示绿色的对勾
        }
        else {
          param.onError(response.data.msg);//会去调用出错的方法
        }
      })
    },
  },
  watch: {
    inputArray: function () {
      this.jsonImg = this.inputArray;
    }
  },
}
</script>
