<template>
  <div>
    <el-table :data="allAreaAdminData" height="250" border :cell-style="{height:'24px',padding:0,margin:0}" size="mini">
      <el-table-column header-align="center" type="index" width="40"></el-table-column>
      <el-table-column header-align="center" label="管理者ID" prop="adminId"></el-table-column>
      <el-table-column header-align="center" label="客户名称" prop="userName"></el-table-column>
      <el-table-column header-align="center" label="客户手机" prop="loginMobile"></el-table-column>
      <el-table-column header-align="center" label="类型" prop="adminType" align="center" width="120">
        <template slot-scope="scope">
          <span v-if="scope.row.adminType">
            运维客户
          </span>
          <span v-else>
            查看客户
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot="header">
          <el-button type="success" icon="el-icon-plus" size="mini" @click="adminAreaDataNew()">新建</el-button>
        </template>
        <template slot-scope="scope">
          <el-button @click="adminAreaDataModify(scope.row)" type="text" size="small">修改</el-button>
          <el-button @click="adminAreaDataDelete(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <p style="color:#ef0000">*注意事项:人员设定后,只会在客户重新登录后生效</p>
    <!--编辑的表单-->

    <el-dialog v-dialogDrag :title="adminAreaDialogParam.title" :visible.sync="adminAreaDialogParam.visible" :width="adminAreaDialogParam.width"
      :top="adminAreaDialogParam.top" :close-on-click-modal='false' append-to-body>
      <el-form ref="areaAdminFormRef" :model="adminAreaData" :rules="adminAreaDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-form-item label="管理帐号" prop="adminId">
          <el-select v-model="adminAreaData.adminId" filterable placeholder="请选择" style="width:100%;" ref="selectCusAdmin" @change="changeCusAdmin">
            <el-option v-for="item in this.allCusAdminData" :key="'allCusAdmin'+item.id" :label="item.userName+'-'+item.loginName+'-'+item.loginMobile"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="用户名称" prop="userName">
          <el-input id="userName" v-model="adminAreaData.userName" placeholder="请输入用户名称" maxlength="100" show-word-limit></el-input>
        </el-form-item>

        <el-form-item label="用户手机" prop="loginMobile">
          <el-input id="loginMobile" v-model="adminAreaData.loginMobile" placeholder="请输入用户手机" maxlength="100" show-word-limit></el-input>
        </el-form-item>

        <el-form-item label="类型" prop="adminType">
          <el-radio-group v-model="adminAreaData.adminType">
            <el-radio :label="false" border>查看客户</el-radio>
            <el-radio :label="true" border>运维客户</el-radio>
          </el-radio-group>
        </el-form-item>

      </el-form>
      <span slot="footer">
        <el-button @click="adminAreaDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="adminAreaDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>

import { getAdminTableData, doAdminNew, doAdminModify, doAdminDelete, getAllCusAdminData } from '@/api/biz/ElecArea'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { required, phone, len, email, integer } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  props: ["areaId", "areaName"],
  data () {
    return {
      /*表格数据 */
      allAreaAdminData: [],
      allCusAdminData: [],
      /*表单的参数 */
      adminAreaDialogParam: //adminArea弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        adminAreaDataAction: '',
        width: '50%',
        top: '5vh',
      },
      /*原始数据 */
      adminAreaRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将adminAreaData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      adminAreaData: {
        areaId: 0,
        areaName: '',
        adminId: '',
        userName: '',
        loginMobile: '',
        adminType: false,

      },
      adminAreaDialogRules:
      {
        areaId: [required],//如果非必填,请去掉,如有其它验证,自行在adminAreaRules参考或新增
        areaName: [required],//如果非必填,请去掉,如有其它验证,自行在adminAreaRules参考或新增
        adminId: [required],//如果非必填,请去掉,如有其它验证,自行在adminAreaRules参考或新增
        userName: [required],//如果非必填,请去掉,如有其它验证,自行在adminAreaRules参考或新增
        loginMobile: [required],//如果非必填,请去掉,如有其它验证,自行在adminAreaRules参考或新增
        adminType: [required],//如果非必填,请去掉,如有其它验证,自行在adminAreaRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
    }
  },
  methods: {
    changeCusAdmin (id) {
      const selectedOption = this.allCusAdminData.find(item => item.id === id);
      this.adminAreaData.userName = selectedOption.userName;
      this.adminAreaData.loginMobile = selectedOption.loginMobile;
    },
    /*-------------------------------------------------表单(adminArea)事件 -----------------------------------------------*/
    /*表单(adminArea)-新建 */
    adminAreaDataNew () {
      this.adminAreaData = JSON.parse(JSON.stringify(this.adminAreaRawData)); //深拷贝一份原始数据到adminAreaData,就相当于重置表单了
      this.adminAreaData.areaId = this.areaId;
      this.adminAreaData.areaName = this.areaName;
      this.adminAreaDialogParam.title = '新建';
      this.adminAreaDialogParam.adminAreaDataAction = 'new';
      this.adminAreaDialogParam.visible = true;
    },
    /*表单(adminArea)-修改 */
    adminAreaDataModify (row) {
      this.adminAreaData = JSON.parse(JSON.stringify(row)); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
      this.adminAreaData.areaId = this.areaId;
      this.adminAreaData.areaName = this.areaName;
      this.adminAreaDialogParam.adminAreaDataAction = 'modify';
      this.adminAreaDialogParam.title = '修改';
      this.adminAreaDialogParam.visible = true;

    },
    /*表单(adminArea)-删除 */
    adminAreaDataDelete (row) {
      this.$confirm('是否确认删除?', '提示', {
        confirmButtonText: '确定',
        cancelButtonText: '取消',
        type: 'warning'
      }).then(() => {
        const delData = JSON.parse(JSON.stringify(row)); //深拷贝一份原始数据到costOutData,就相当于重置表单了
        doAdminDelete(delData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }).catch(() => {
      });
    },
    adminAreaDataValidate () {
      let result = true;
      this.$refs.areaAdminFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(adminArea)-提交 */
    adminAreaDataSubmit () {
      if (!this.adminAreaDataValidate()) return;
      this.adminAreaDialogParam.visible = false;
      const postData = JSON.parse(JSON.stringify(this.adminAreaData));
      if (this.adminAreaDialogParam.adminAreaDataAction == 'new') {
        doAdminNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh(this.areaId);
          }
          else {
            this.notifyWarning(response.data.msg);
            this.adminAreaDialogParam.visible = true;
          }
        })
      }
      else if (this.adminAreaDialogParam.adminAreaDataAction == 'modify') {
        doAdminModify(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh(this.areaId);
          }
          else {
            this.notifyWarning(response.data.msg);
            this.adminAreaDialogParam.visible = true;
          }
        })
      }
    },
    /*表格-数据重置 */
    tableDateRefresh (areaIdParam) {

      getAdminTableData({ areaId: areaIdParam }).then(response => {
        if (response.data.success) {
          console.log(response.data.data);
          this.allAreaAdminData = response.data.data;
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });

    },

  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {
    areaId: function () {
      this.tableDateRefresh(this.areaId);
    }
  },
  filters: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.adminAreaRawData = JSON.parse(JSON.stringify(this.adminAreaData)); //深拷贝一份原始数据到adminAreaRawData
    getAllCusAdminData().then(response => {
      if (response.data.success) {
        this.allCusAdminData = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });

    this.tableDateRefresh(this.areaId);
  }
}
</script>
