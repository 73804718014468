import dayjs from 'dayjs';
/**
 * 全局的过滤器
 */
/*计算文件的大写 */

const sizeFilter = function (value) {
  if (value) {
    if (parseFloat(value / 1024).toFixed(2) > 512) {
      return parseFloat(value / 1024 / 1023).toFixed(2) + 'M'
    }
    else {
      return parseFloat(value / 1024).toFixed(2) + 'K'
    }
  }
  else {
    return "未知大小";
  }
}
/*计算金额的大小 */
const numFilter = function (value) {
  if (value) {
    if (Math.abs(parseFloat(value) / 1000) > 5) {
      return parseFloat(value / 10000).toFixed(2) + '万'
    }
    else {
      return parseFloat(value).toFixed(2) + '';
    }
  }
  else {
    return "0.00";
  }
}
/*日期的格式化 */
const dateFilter = function (value) {
  if (value) {
    return new dayjs(value).format('YYYY-MM-DD');
  }
  else {
    return "";
  }
}
const dateTimeFilter = function (value) {
  if (value) {
    return new dayjs(value).format('YYYY-MM-DD hh:mm');
  }
  else {
    return "0.00";
  }
}
const nullNumFilter = function (value) {
  if (value) {
    return parseFloat(value).toFixed(2) + '';
  }
  else {
    return "0.00";
  }
}
/*分转换成元过滤 */
const yuanFilter = function (value) {
  if (value) {
    return parseFloat(value / 100).toFixed(2) + '';
  }
  else {
    return "0.00";
  }
}


/*税率过滤 */
const rateFilter = function (value) {
  if (value) {
    return value + "%";
  }
  else {
    return "0%";
  }
}
/*公司名称过滤 */
const cmpFilter = function (value) {
  if (value)
    return value
      .replace("电子信息产业股份有限公司", '')
      .replace("信息产业股份有限公司", '')
      .replace("信息产品有限责任公司", '')
      .replace("信息产业有限责任公司", '')
      .replace("信息技术有限公司", '')
      .replace("信息产业有限公司", '')
      .replace("股份有限公司", '')
      .replace("技术有限公司", '')
      .replace("科技开发有限公司", '')
      .replace("科技发展有限公司", '')
      .replace("科技有限公司", '')
      .replace("有限责任公司", '')
      .replace('有限公司', '')
      .replace('报业集团', '');
  else
    return '';
}
/*销售名称过滤 */
const saleNameFilter = function (value) {
  if (value) {
    let valArray = value.split('-');
    if (valArray.length > 0) {
      return valArray[valArray.length - 1];
    }
  }
  else {
    return value;
  }
}
const replaceNewline = function (value) {
  if (value) {
    return value.replace(/\n/g, '<br>');
  }
  else {
    return value;
  }
}
/**跟据时间日期得到第几季度 */
const seasonFilter = function (value) {
  let tempDate = new Date(value);
  let tempYear = tempDate.getFullYear();
  let monthNum = Math.ceil((tempDate.getMonth() + 1) / 3);
  return tempYear + '年第' + monthNum + '季度';
}

export { sizeFilter, numFilter, nullNumFilter, rateFilter, cmpFilter, saleNameFilter, seasonFilter, dateFilter, dateTimeFilter, replaceNewline, yuanFilter }