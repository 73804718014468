<!-- 首页  -->
<template>
  <div>
    <el-row>
      <el-breadcrumb separator-class="el-icon-arrow-right">
        <el-breadcrumb-item :to="{ name: this.$store.getters.getLoginUser().home }">首页</el-breadcrumb-item>
        <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
      </el-breadcrumb>
    </el-row>
    <set-info></set-info>
    <p></p>
    <el-row :gutter="20">
      <el-col :span="8">
        <dir-info></dir-info>
      </el-col>
      <el-col :span="8">
        <java-info></java-info>
      </el-col>
      <el-col :span="8">
        <cer-info></cer-info>
      </el-col>
    </el-row>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>
import store from '@/store';
import SetInfo from '@/components/utils/SetInfo.vue';
import JavaInfo from '@/components/utils/JavaInfo.vue';
import CerInfo from '@/components/utils/CerInfo.vue';
import DirInfo from '@/components/utils/DirInfo.vue';


export default {
  //import引入的组件需要注入到对象中才能使用
  components: { CerInfo, JavaInfo, DirInfo, SetInfo },
  data () {

    //这里存放数据
    return {
      direcParam: {
        'directName': '后端管理用户'
      },
      keyDialogVisible: false,
    }
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {},
  //方法集合
  methods: {
    /*-------------------------------------------------特殊事件 -----------------------------------------------*/
    /*特殊事件-出错跟踪 */
    notifyInfo (info) { this.$notify.info({ title: '提示', message: info }); },
    notifySuccess (success) { this.$notify.success({ title: '成功', message: success }); },
    notifyWarning (warning) { this.$notify.warning({ title: '警告', message: warning }); },
    notifyError (error) { this.$notify.error({ title: '错误', message: error }); },

    /*上传cer文件类型判断,默认doc,docx,pdf是都可以上传*/
    cerSubmitBefore (file) {
      const fileType = file.name.substring(file.name.lastIndexOf('.'))
      if ('.cer'.indexOf(fileType) == -1) {  //允许的文件类型请自己修改,但请注意,是带.的
        this.notifyWarning('上传失败,请上传.cer文件')
        return false;
      }
      else {
        return true;
      }
    },
    cerUploadSubmit (param) {
      const cerFileData = new FormData()
      cerFileData.append('file', param.file);
      postCerFile(cerFileData).then(response => {
        if (response.data.success) {
          this.cerInfo = response.data.data;
          //this.formData.cer = jsonData.serverPath;
          /*this.formData.imageUrl = jsonData.fileImages;
          this.formData.title = jsonData.fileName;
          this.formData.note = jsonData.fileNote;
          this.editorHtmlData = jsonData.fileContent;*/
          param.onSuccess();  // 上传成功的图片会显示绿色的对勾
        }
        else {
          param.onError(response.data.msg);//会去调用出错的方法
        }
      }).catch(error => {
        param.onError(error); //会去调用出错的解决办法
      })
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.direcParam = JSON.parse(store.getters.getActiveCus());
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>
