<template>
  <el-card class="box-card">
    <div class="text item">
      <el-form ref="settingDataForm" :model="settingData" :rules="settingRules" label-width="120px" size="small">
        <el-row :gutter="20">
          <el-col :span="12">
            <p><i class="el-icon-lock" style="color:#67C23A"></i> 平台配置信息</p>
            <el-form-item label="站点标题" prop="siteTitle">
              <el-input v-model="settingData.siteTitle" placeholder="请输入站点标题"></el-input>
            </el-form-item>
            <el-form-item label="站点副标题" prop="siteSub">
              <el-input v-model="settingData.siteSub" placeholder="请输入站点副标题"></el-input>
            </el-form-item>
            <el-form-item label="系统名称" prop="sysName">
              <el-input v-model="settingData.sysName" placeholder="请输入系统名称"></el-input>
            </el-form-item>
            <el-form-item label="扫码下载" prop="pathEnable">
              <el-checkbox v-model="settingData.pathEnable">启用后,[常用下载]的二维码可以下载文件,但有企业隐私风险,请谨慎!</el-checkbox>
            </el-form-item>
            <el-form-item label="网站全路径" prop="pathUrl">
              <el-input v-model="settingData.pathUrl" placeholder="请输入网站全路径"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <p><i class="fa fa-bolt" style="color:#67C23A"></i> 全站充电参数设置</p>
            <el-form-item label="适用范围"><span style="color:#67C23A">*如果小区不设定充电参数,全站默认配置</span>
            </el-form-item>
            <el-form-item label="空载断电时长" prop="elecNullSecond">
              <el-input v-model="settingData.elecNullSecond" placeholder="请输入空载断电时长" type="number" :min="30" :max="240">
                <template slot="append">秒</template></el-input>
            </el-form-item>
            <el-form-item label="允许最大功率" prop="elecMaxPower">
              <el-input v-model="settingData.elecMaxPower" placeholder="请输入允许最大功率" type="number" :min="10" :max="10000">
                <template slot="append">瓦</template></el-input>
            </el-form-item>
            <el-form-item label="充满自停功率" prop="elecFullPower">
              <el-input v-model="settingData.elecFullPower" placeholder="请输入设备充满自停功率" type="number" :min="10" :max="1000">
                <template slot="append">瓦</template></el-input>
            </el-form-item>
            <el-form-item label="充满等待时间" prop="elecWaitSecond">
              <el-input v-model="settingData.elecWaitSecond" placeholder="请输入设备充满等待时间" type="number" :min="10" :max="10000">
                <template slot="append">秒</template></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item style="text-align: right;">
          <el-button type="primary" @click="submitForm('settingDataForm')">修 改</el-button>
          <el-button @click="resetForm('settingDataForm')">重 置</el-button>
        </el-form-item>
      </el-form>
    </div>
  </el-card>
</template>
 <script> 
import { getSetting, doModify } from '@/api/wbkj/Setting.js';
import { required } from "@/utils/formRules"
export default {
  data () {
    return {
      settingData: {
        siteTitle: "汇宇新陇-宇充充-设备管理平台",
        siteSub: "管理登录",
        sysName: '设备管理平台',
        pathEnable: false,
        pathUrl: "http://dev.easyhy.com/",
        elecNullSecond: 100,
        elecMaxPower: 600,
        elecFullPower: 20,
        elecWaitSecond: 3600,
      },
      settingRules:
      {
        siteTitle: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        siteSub: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        sysName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        elecNullSecond: [required],
        elecMaxPower: [required],
        elecFullPower: [required],
        elecWaitSecond: [required],
      },
    }
  },
  methods: {
    initSetting () {
      getSetting().then(response => {
        if (response.data.success) {
          this.settingData = response.data.data;
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      })
    },
    submitForm (formName) {
      console.log(formName);
      this.$refs[formName].validate((valid) => {
        if (valid) {
          doModify(this.settingData).then(response => {
            if (response.data.success) {
              this.notifySuccess(response.data.msg);
            }
            else {
              this.notifyWarning(response.data.msg);
            }
          })
        } else {
          console.log('error submit!!');
          return false;
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
  },
  mounted () {
    this.initSetting();
  },
  watch: {

  }
}
</script>