import Vue from 'vue'
import VueRouter from 'vue-router'
import AdminMain from '@/views/AdminMain.vue'
import HomeIndex from '@/views/home/AdminHome.vue' //管理员主页
import DocInfo from '@/views/wbkj/DocInfo.vue'
import Downloads from '@/views/wbkj/DownloadsSet.vue'
import Menus from '@/views/wbkj/MenusSet.vue'
import Departs from '@/views/wbkj/DepartsSet.vue'
import Links from '@/views/wbkj/LinksSet.vue'
import Images from '@/views/wbkj/ImagesSet.vue'
import UserLogin from '@/views/UserLogin.vue'
import DocPanel from '@/views/wbkj/DocPanelSet.vue'
import CusPanel from '@/views/wbkj/CusPanelSet.vue'
import Role from '@/views/wbkj/RoleSet.vue'
import IpPermit from '@/views/wbkj/IpPermitSet.vue'
import AdminSet from '@/views/wbkj/AdminSet.vue' //后台账号管理
import UserInfo from '@/views/wbkj/UserInfo.vue' //
import Company from '@/views/wbkj/CompanySet.vue'
import Employee from '@/views/wbkj/EmployeeSet.vue'
import BackUp from '@/views/wbkj/BackUpSet.vue'//备份管理
import SettingInfo from '@/views/wbkj/SettingInfo.vue'//网站配置项
import TestCreate from '@/views/wbkj/TestCreateSet.vue'
import Leave from '@/views/wbkj/LeaveSet.vue'

import DevKind from '@/views/dev/KindSet.vue'
import DevKindParam from '@/views/dev/KindParamSet.vue'
import DevInfo from '@/views/dev/InfoSet.vue'

import DevLogs from '@/views/dev/LogsSet.vue'
import DevLogsSub from '@/views/dev/LogsSubSet.vue'

import ElecUsers from '@/views/biz/ElecUsersSet.vue' //用户管理
import ElecOrders from '@/views/biz/ElecOrdersSet.vue'
import ElecInpour from '@/views/biz/ElecInpourSet.vue'
import ElecShareAccount from '@/views/biz/ElecShareAccountSet.vue'  //结算收款帐号
import BizPrices from '@/views/biz/BizPricesSet.vue'//收费标准
import ElecArea from '@/views/biz/ElecAreaSet.vue'
//import TechCostOut from '@/views/tech/CostOutSet.vue' //费用审批


Vue.use(VueRouter)
//2.将路由组用引入到路由中去,说白了就是路径和容器对应
const routes = [
  { path: '/', redirect: '/admin' }, //默认项
  { path: '/login', name: 'login', component: UserLogin },//登录
  {
    path: '/admin',
    component: AdminMain, //主容器
    redirect: '/homeIndex',
    children: [

      { path: '/homeIndex', name: 'homeIndex', component: HomeIndex },//首页
      { path: '/role', name: 'role', component: Role }, //角色管理
      { path: '/docInfo/:id', name: 'docInfo', component: DocInfo },
      { path: '/downloads', name: 'downloads', component: Downloads },
      { path: '/menus', name: 'menus', component: Menus },
      { path: '/departs', name: 'departs', component: Departs },
      { path: '/links', name: 'links', component: Links },
      { path: '/images', name: 'images', component: Images },
      { path: '/docPanel', name: 'docPanel', component: DocPanel },
      { path: '/cusPanel', name: 'cusPanel', component: CusPanel },
      { path: '/ipPermit', name: 'ipPermit', component: IpPermit },
      { path: '/adminSet', name: 'adminSet', component: AdminSet },
      { path: '/userInfo', name: 'userInfo', component: UserInfo },
      { path: '/company', name: 'company', component: Company }, //公司设定
      { path: '/employee', name: 'employee', component: Employee }, //员工设定
      { path: '/backUp', name: 'backUp', component: BackUp }, //请假
      { path: '/settingInfo', name: 'settingInfo', component: SettingInfo },
      { path: '/testCreate', name: 'testCreate', component: TestCreate },
      { path: '/devKind', name: 'devKind', component: DevKind },//设备分类
      { path: '/devKindParam', name: 'devKindParam', component: DevKindParam },//设备参数指令
      { path: '/devInfo', name: 'devInfo', component: DevInfo },//设备信息
      { path: '/devLogs', name: 'devLogs', component: DevLogs },//设备事件
      { path: '/devLogsSub', name: 'devLogsSub', component: DevLogsSub },//设备事件

      { path: '/elecUsers', name: 'elecUsers', component: ElecUsers },//用户管理
      { path: '/elecOrders', name: 'elecOrders', component: ElecOrders },//订单管理
      { path: '/elecInpour', name: 'elecInpour', component: ElecInpour },//充值记录
      { path: '/elecShareAccount', name: 'elecShareAccount', component: ElecShareAccount },//结算收款帐号
      { path: '/bizPrices', name: 'bizPrices', component: BizPrices },//收费标准
      { path: '/elecArea', name: 'elecArea', component: ElecArea },//小区管理
      { path: '/leave', name: 'leave', component: Leave },//留言管理


    ]
  }
]
const router = new VueRouter({
  routes // (缩写) 相当于 routes: routes
})
// 4.把这个暴露出来,其它js才可以去使用它
export default router