<!--此组件是为了无限生成权限菜单的集联,包括了调用本身-->
<template>
  <div>
    <div v-if="panel.isNode&&panel.bodyItems.length>0"> <!--是分支-->
      <hr style="height:1px;width: 99%;border:none;border-top:1px dashed #DCDFE6">
      <el-form-item label-width="140px" :label="panel.viewName" style="font-size: 24px;font-weight:bold;color:#409EFF !important;">
        <el-switch v-model="rightList.selectPanel['group_'+panel.id]" :target="'selectPanel@group_'+panel.id" :active-value="panel.id+'@true'"
          :inactive-value="panel.id+'@false'" active-text="全选" inactive-text="不选" @change="changePanelAll" style="margin-right:30px;">
        </el-switch>

      </el-form-item>
      <div :ref="'panel_'+panel.id">
        <role-panel v-for="item in panel.bodyItems" :key="item.id" :panel="item" :rightJson="rightList"></role-panel>
      </div>
    </div>
    <div v-else><!--是结点-->
      <el-form-item label-width="140px" :label="panel.viewName">
        <div :ref="'panel_'+panel.id">
          <el-switch v-model="rightList.selectPanel['group_'+panel.id]" :target="'selectPanel@group_'+panel.id" :active-value="panel.id+'@true'"
            :inactive-value="panel.id+'@false'" active-text="全选" inactive-text="不选" @change="changePanelAll" style="margin-right:30px;">
          </el-switch>
          <span v-if="panel.linkType==1"><!--自定义模块-->
            <el-checkbox :target="'cusPanel@'+panel.rightId+'_see'" v-model="rightList.cusPanel[panel.rightId+'_see']" true-label="1" false-label="0"
              @change="forceUpdate">查看</el-checkbox>
            <el-checkbox :target="'cusPanel@'+panel.rightId+'_'+right.id" v-for="right in panel.rightJson" :key="panel.rightId+'_'+right.id"
              v-model="rightList.cusPanel[panel.rightId+'_'+right.id]" true-label="1" false-label="0" :title="right.note"
              @change="forceUpdate">{{ right.name }}</el-checkbox>
          </span>
          <span v-else-if="panel.linkType==2"><!--文章模块-->
            <el-checkbox :target="'docPanel@'+panel.rightId+'_see'" v-model="rightList.docPanel[panel.rightId+'_see']" true-label="1" @change="forceUpdate"
              false-label="0">查看</el-checkbox>
            <el-checkbox :target="'docPanel@'+panel.rightId+'_new'" v-model="rightList.docPanel[panel.rightId+'_new']" true-label="1" @change="forceUpdate"
              false-label="0">新建</el-checkbox>
            <el-checkbox :target="'docPanel@'+panel.rightId+'_modify'" v-model="rightList.docPanel[panel.rightId+'_modify']" true-label="1"
              @change="forceUpdate" false-label="0">修改</el-checkbox>
            <el-checkbox :target="'docPanel@'+panel.rightId+'_del'" v-model="rightList.docPanel[panel.rightId+'_del']" true-label="1" @change="forceUpdate"
              false-label="0">删除</el-checkbox>
            <!-- <el-checkbox :target="'docPanel@'+panel.rightId+'_check'" v-model="rightList.docPanel[panel.rightId+'_check']" true-label="1" @change="forceUpdate"
              false-label="0">审核</el-checkbox> -->
            <el-checkbox :target="'docPanel@'+panel.rightId+'_public'" v-model="rightList.docPanel[panel.rightId+'_public']" true-label="1"
              @change="forceUpdate" false-label="0">发布</el-checkbox>
            <el-checkbox :target="'docPanel@'+panel.rightId+'_top'" v-model="rightList.docPanel[panel.rightId+'_top']" true-label="1" @change="forceUpdate"
              false-label="0">置顶</el-checkbox>
            <el-checkbox :target="'docPanel@'+panel.rightId+'_trans'" v-model="rightList.docPanel[panel.rightId+'_trans']" true-label="1" @change="forceUpdate"
              false-label="0">转发</el-checkbox>
            <!-- <el-checkbox :target="'docPanel@'+panel.rightId+'_open'" v-model="rightList.docPanel[panel.rightId+'_open']" true-label="1" @change="forceUpdate"
              false-label="0">弹窗</el-checkbox> -->
            <el-checkbox :target="'docPanel@'+panel.rightId+'_scope'" v-model="rightList.docPanel[panel.rightId+'_scope']" true-label="1" @change="forceUpdate"
              false-label="0">数据:全部/个人</el-checkbox>
          </span>
        </div>
      </el-form-item>
    </div>
  </div>
</template>
<script> 
export default {
  name: 'RolePanel',
  props: ["panel", "rightJson"],
  data () {
    return {
      rightList: {
        docPanel: {}, cusPanel: {}, selectPanel: {}
      },
    };
  },
  methods: {

    forceUpdate () {
      this.$forceUpdate();
    },
    changePanelAll (value) {
      let twodun = value.split('@');
      console.log(this.$refs['panel_' + twodun[0]]);
      let allChild = this.$refs['panel_' + twodun[0]].children;
      this.changeChildren(allChild, twodun[1]);
    },
    /**
     * 改变所有下级组件 el-Checkbox的值
     * @param {*} arr 
     * @param {*} TrueOrFalse 
     */
    changeChildren (allChild, TrueOrFalse) {
      for (let i = 0; i < allChild.length; i++) {
        if (allChild[i].className.indexOf("el-checkbox") >= 0) {
          let dataId = allChild[i].getAttribute('target');
          if (dataId) {
            let dataTag = dataId.split('@');
            if (TrueOrFalse == 'true') {
              this.rightList[dataTag[0]][dataTag[1]] = "1";
            } else {
              this.rightList[dataTag[0]][dataTag[1]] = "0";
            }
          }
        }
        else if (allChild[i].className.indexOf("el-switch") >= 0) {
          let dataId = allChild[i].getAttribute('target');
          if (dataId) {
            let dataTag = dataId.split('@');
            let dataPanel = dataId.split('_');

            this.rightList[dataTag[0]][dataTag[1]] = dataPanel[1] + "@" + TrueOrFalse;
          }
        }
        if (allChild[i].children) {
          if (allChild[i].children.length) {

            this.changeChildren(allChild[i].children, TrueOrFalse)
          }
        }
      }
    },
  },
  watch: {
    panel: function () {
      //console.log(this.inputPanel);
    },
    rightJson: function () {
      //console.log(this.rightJson);
      this.rightList = this.rightJson;
    }
  },
  mounted () {
    this.rightList = this.rightJson;
  },
}
</script>