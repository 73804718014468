<!--  -->
<template>
  <div style="background-color: rgb(84, 92, 100);">
    <el-menu default-active="home" class="el-menu-vertical-demo" :collapse="isCollapse" background-color="#545c64" text-color="#fff" active-text-color="#ffd04b"
      model="vertical" :default-openeds=defaultOpen>
      <h3 style="display:flex"><img :src='`/icon/logo2.png`' width="50px" />{{isCollapse?'':settingData.sysName}}</h3>
      <template v-for="node in menuData">
        <el-submenu v-if="node.isNode" :key="node.id" :index="'left_'+node.id" :style="node.bodyItems.length>0?'display:block':'display:none'">
          <template slot="title">
            <i :class="node.iconHref"></i>
            <span slot="title">{{node.viewName}}</span>
          </template>
          <template v-for="item in node.bodyItems">
            <el-menu-item style="height: 34px;line-height:34px;" :key="'left_'+item.routeIndex+item.id" :index="item.routeIndex"
              @click="clickMenuItem(item)">{{item.viewName}}</el-menu-item>
          </template>
        </el-submenu>
        <el-menu-item style="height: 34px;line-height:34px;" v-else :key="'left_'+item.routeIndex+node.id" :index="node.routeIndex"
          @click="clickMenuItem(item)">{{item.viewName}}</el-menu-item>
      </template>
    </el-menu>
  </div>
</template>
<style lang="less" scoped>
.el-menu-vertical-demo:not(.el-menu--collapse) {
  width: 200px;
  min-width: 200px;
  min-height: 400px;
}
.el-menu {
  height: 100vh;
  border: 0;
  width: auto;
  h3 {
    padding-left: 10px;
    color: #fff;
    text-align: center;
    line-height: 48px;
    font-size: 16px;
    font-weight: 400;
  }
  li {
    ul {
      height: 300%;
    }
  }
}
</style>

<script> 
import { getSetting } from '@/api/wbkj/Setting';
export default {
  props: ['menuData'],
  data () {
    return {
      settingData: {
        siteTitle: "昆明微布科技有限公司",
        siteSub: "管理系统",
        sysName: '管理后端',
        pathEnable: false,
        pathUrl: "http://www.luoui.com/"
      },
      defaultOpen: []
    };
  },
  methods: {
    clickMenuItem (bodyItem) {
      if (bodyItem.linkType == 1) {
        let tempRoutePath = "/" + bodyItem.routeIndex;

        this.$store.commit('setActiveCus', { id: bodyItem.linkCusPanelId, directName: bodyItem.viewName, routeIndex: bodyItem.routeIndex, routePath: tempRoutePath }); //存到Store里,store已经存在cookie里了,万一刷新页面不丢
        if (!(this.$route.name == bodyItem.routeIndex)) {
          this.$router.push({
            name: bodyItem.routeIndex,
          });
        }
      }
      else if (bodyItem.linkType == 2) {
        let tempRoutePath = "/" + bodyItem.routeIndex + "/" + bodyItem.linkDocPanelId;

        this.$store.commit('setActiveCus', { id: bodyItem.linkDocPanelId, directName: bodyItem.viewName, routeIndex: bodyItem.routeIndex, routePath: tempRoutePath }); //存到Store里,store已经存在cookie里了,万一刷新页面不丢
        if (!(this.$route.path == tempRoutePath)) {
          this.$router.push({
            name: bodyItem.routeIndex, params: { id: bodyItem.linkDocPanelId }
          });
        }
      }
    },
    initShow () {
      for (let i = 0; i < this.menuData.length; i++) {
        this.defaultOpen.push('left_' + this.menuData[i].id);
      }
    }
  },
  mounted () {
    getSetting().then(response => {
      if (response.data.success) {
        this.settingData = response.data.data;
        this.initShow();
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
  },
  computed: {
    isCollapse () {
      return this.$store.state.adminState.isCollapse;
    }

  }
}
</script>