<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="19">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }} <span style="margin-left:20px;color:#ef0000">*审核通过的结算收款帐号,不可修改,不可删除</span></el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="5">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" style="width:100%" @change="tableChangeSearchKey">
            <el-button type="primary" slot="append"><i class="fa fa-search" style="color:#409EFF"></i></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-button-group>
          <el-button @click="formDataNew" type="primary">新建</el-button>
          <el-button @click="formDataModify" type="primary">修改</el-button>
          <el-button @click="formDataDelete" type="primary">删除</el-button>
          <el-button @click="formDataPermit" type="success">审核</el-button>
        </el-button-group>
      </el-col>
    </el-row>

    <el-table ref="elecShareAccountTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}"
      :data="tableData" tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange"
      @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" label="ID" prop="id" width="80"></el-table-column>
      <el-table-column header-align="center" label="类" prop="legalType" width="60">
        <template slot-scope="scope">
          <el-tag v-if="scope.row.legalType">公</el-tag>
          <el-tag v-else type="success">私</el-tag>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="显名" prop="linkMan" width="120">
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <div slot="content" style="line-height:20px;">
              行政区域:
              <span v-if="scope.row.areaLimt.province">{{scope.row.areaLimt.provinceName}}</span>
              <span v-if="scope.row.areaLimt.city">/{{scope.row.areaLimt.cityName}}</span>
              <span v-if="scope.row.areaLimt.area">/{{scope.row.areaLimt.areaName}}</span><br />
              联系电话:{{scope.row.linkMobile}}<br />
              微信ID:{{scope.row.wxId}}<br />
              <!-- -----------------------以下说明-----------------------<br /> -->
              <!-- <span v-html="formatTooltipContent(item.note)"></span> -->
            </div>
            <el-tag>{{scope.row.linkMan}}</el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="法人信息" prop="legalName" width="120">
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <div slot="content" style="line-height:20px;">
              法人姓名:{{scope.row.legalName}}<br />
              法人证件号:{{scope.row.legalID}}<br />
              注册地址:{{scope.row.legalAddress}}<br />
              企业名称:{{scope.row.legalCmpName}}<br />
              社会信用代码:{{scope.row.legalCode}}<br />
            </div>
            <el-tag>{{scope.row.legalName}}</el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="经营信息" prop="scopeInfo" show-overflow-tooltip>
        <template slot-scope="scope">
          ({{scope.row.scopeInfo.mcc}})
          <span v-if="scope.row.scopeInfo.oneId">{{scope.row.scopeInfo.oneName}}</span>
          <span v-if="scope.row.scopeInfo.twoId">/{{scope.row.scopeInfo.twoName}}</span>
          <span v-if="scope.row.scopeInfo.threeId">/{{scope.row.scopeInfo.threeName}}</span>
          <span v-if="scope.row.scopeInfo.address">{{scope.row.scopeInfo.address}}</span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="开户信息" prop="legalName" width="320">
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <div slot="content" style="line-height:20px;">
              开户名:{{scope.row.bankAccoutName}}<br />
              开户帐号:{{scope.row.bankCode}}<br />
              开户地区:<span v-if="scope.row.bankState.province">{{scope.row.bankState.provinceName}}</span>
              <span v-if="scope.row.bankState.city">/{{scope.row.bankState.cityName}}</span><br />
              开户银行:{{scope.row.bankName}}<br />
              开户支行:{{scope.row.bankBranchName}}<br />
            </div>
            <el-tag>{{scope.row.bankAccoutName}}</el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="证件材料" width="200" prop="proveFile">
        <template slot-scope="scope">
          <file-view :fileList="scope.row.proveFile" :showAll="true"> </file-view>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="实景(商户经营实景照片)" width="200" prop="siteFile">
        <template slot-scope="scope">
          <file-view :fileList="scope.row.siteFile"> </file-view>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="状态" width="120">
        <template slot-scope="scope">
          <span style="margin-left:5px">
            <i class="fa fa-weixin" style="color:#409EFF;font-size:16px;" title="微信认证通过" v-if="scope.row.passWx"></i>
            <i class="fa fa-weixin" style="font-size:16px;" title="微信未认证" v-else></i>
          </span>
          <span style="margin-left:5px">
            <el-image v-if="scope.row.passAlipay" src="icon/svg/ali.svg" style="width:16px;height:16px;" title="支付宝认证通过"></el-image>
            <el-image v-else src="icon/svg/ali2.svg" style="width:16px;height:16px;" title="支付宝未认证"></el-image>
          </span>
          <span style="margin-left:5px">
            <i class="fa fa-lock" v-if="scope.row.isPublic" style="color:#409EFF;font-size:20px;" title="已审核通过"></i>
            <i v-else class="fa fa-unlock-alt" style="font-size:20px;" title="未审核通过"></i>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>

    <!--编辑的表单-->

    <el-dialog v-dialogDrag :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width" :top="formDialogParam.top"
      :close-on-click-modal='false'>
      <el-form ref="elecShareAccountFormRef" :model="formData" :rules="formDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-tabs value="first">
          <el-tab-pane label="基本信息" name="first" active>
            <el-form-item label="账户类型" prop="legalType" size="large">
              <el-radio-group v-model="formData.legalType">
                <el-radio :label="false" border>对私打款</el-radio>
                <el-radio :label="true" border>对公打款</el-radio>
              </el-radio-group>
            </el-form-item>
            <el-row style="padding:0px;" :gutter="20">
              <el-col :span="8">
                <el-form-item label="微信ID" prop="wxId">
                  <el-input id="wxId" v-model="formData.wxId" placeholder="请输入微信ID" maxlength="40" show-word-limit></el-input>

                </el-form-item>
              </el-col>
              <el-col :span="16">
                <span style="color: #ef0000;line-height:36px ;">*获取方式：打开微信→充电平台公众号→个人中心→头像旁ID号</span>
              </el-col>
            </el-row>

            <el-row style="padding:0px;" :gutter="20">
              <el-col :span="8">
                <el-form-item label="行政区域" prop="areaLimt.province">
                  <el-select v-model="formData.areaLimt.province" filterable placeholder="选择省份" @change="changeProvince" style="width:100%">
                    <el-option v-for="item in this.allProvince" :key="'allProvince'+item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="城市" prop="areaLimt.city">
                  <el-select v-model="formData.areaLimt.city" filterable placeholder="选择城市" @change="changeCity" style="width:100%">
                    <el-option v-for="item in this.allCity" :key="'allCity'+item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="区县" prop="areaLimt.area">
                  <el-select v-model="formData.areaLimt.area" filterable placeholder="选择区县" @change="changeArea" style="width:100%">
                    <el-option v-for="item in this.allArea" :key="'allArea'+item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
              </el-col>
            </el-row>
            <el-row style="padding:0px;" :gutter="20">
              <el-col :span="8">
                <el-form-item label="显名" prop="linkMan">
                  <el-input id="linkMan" v-model="formData.linkMan" placeholder="请输入显名" maxlength="100" show-word-limit></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="8">
                <el-form-item label="联系电话" prop="linkMobile">
                  <el-input id="linkMobile" v-model="formData.linkMobile" placeholder="请输入联系电话" maxlength="11" show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
          </el-tab-pane>
          <el-tab-pane label="证件信息" name="second">
            <el-row style="padding:0px;" :gutter="20">
              <el-col :span="12">
                <el-form-item label="法人姓名" prop="legalName">
                  <el-input id="legalName" v-model="formData.legalName" placeholder="请输入法人姓名" maxlength="100" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="注册地址" prop="legalAddress">
                  <el-input id="legalAddress" v-model="formData.legalAddress" placeholder="请输入注册地址" maxlength="100" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="信用代码" prop="legalCode">
                  <el-input id="legalCode" v-model="formData.legalCode" placeholder="请输入社会信用代码" maxlength="40" show-word-limit></el-input>
                </el-form-item>
              </el-col>
              <el-col :span="12">
                <el-form-item label="证件号码" prop="legalID">
                  <el-input id="legalID" v-model="formData.legalID" placeholder="请输入法人证件号" maxlength="40" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="企业名称" prop="legalCmpName">
                  <el-input id="legalCmpName" v-model="formData.legalCmpName" placeholder="请输入企业名称" maxlength="100" show-word-limit></el-input>
                </el-form-item>
              </el-col>
            </el-row>
            <el-form-item label="附件" prop="proveFile">
              <div style="color: #ef0000;line-height:32px ;">对私打款需要:法人身份证正面+反面+银行卡&nbsp;&nbsp;&nbsp;&nbsp;对公打款需要:法人身份证正面+反面+营业执照+开户许可证</div>
              <upload-json-img id="proveFile" :inputArray="formData.proveFile" label="附件(法人身份证正面+反面+营业执照等)" childDir="elecShareAccount"
                @success="proveFileSuccessUpload"></upload-json-img>
            </el-form-item>
          </el-tab-pane>
          <el-tab-pane label="开户银行信息" name="third">
            <el-row style="padding:0px;" :gutter="20">
              <el-col :span="12">
                <el-form-item label="开户名" prop="bankAccoutName">
                  <el-input id="bankAccoutName" v-model="formData.bankAccoutName" placeholder="请输入开户名" maxlength="100" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="开户-省" prop="bankState">
                  <el-select v-model="formData.bankState.province" filterable placeholder="选择省份" @change="changeBankProvince" style="width:100%">
                    <el-option v-for="item in this.allProvince" :key="'allProvince'+item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="开户银行" prop="bankName">
                  <el-autocomplete v-model="formData.bankName" :fetch-suggestions="querySearch" placeholder="请输入开户银行"></el-autocomplete>
                </el-form-item>
              </el-col>
              <el-col :span="12"><el-form-item label="开户帐号" prop="bankCode">
                  <el-input id="bankCode" v-model="formData.bankCode" placeholder="请输入开户帐号" maxlength="60" show-word-limit></el-input>
                </el-form-item>
                <el-form-item label="城市" prop="bankState">
                  <el-select v-model="formData.bankState.city" filterable placeholder="选择城市" @change="changeBankCity" style="width:100%">
                    <el-option v-for="item in this.allBankCity" :key="'allBankCity'+item.id" :label="item.name" :value="item.id">
                    </el-option>
                  </el-select>
                </el-form-item>
                <el-form-item label="开户支行" prop="bankBranchName">
                  <el-input id="bankBranchName" v-model="formData.bankBranchName" placeholder="请输入开户支行" maxlength="100" show-word-limit></el-input>
                </el-form-item></el-col>
            </el-row>

          </el-tab-pane>
          <el-tab-pane label="经营信息" name="fourth">
            <el-form-item label="类目1" prop="scopeInfo.oneId">
              <el-select v-model="formData.scopeInfo.oneId" filterable placeholder="选择类目1" @change="changeBizKindOne" style="width:100%">
                <el-option v-for="item in this.allKindOne" :key="'allKindOne'+item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="类目2" prop="scopeInfo.twoId">
              <el-select v-model="formData.scopeInfo.twoId" filterable placeholder="选择类目2" @change="changeBizKindTwo" style="width:100%">
                <el-option v-for="item in this.allKindTwo" :key="'allKindTwo'+item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="类目3" prop="scopeInfo.threeId">
              <el-select v-model="formData.scopeInfo.threeId" filterable placeholder="选择类目3" @change="changeBizKindThree" style="width:100%">
                <el-option v-for="item in this.allKindThree" :key="'alallKindThreelArea'+item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
            <el-form-item label="经营地址" prop="scopeInfo.address">
              <el-input v-model="formData.scopeInfo.address" placeholder="请输入经营地址" maxlength="100" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="实景" prop="siteFile">
              <div style="color: #ef0000;line-height:32px ;">商户经营实景照片</div>
              <upload-json-img id="siteFile" :inputArray="formData.siteFile" label="附件(场地材料,多个)" childDir="elecShareAccount"
                @success="siteFileSuccessUpload"></upload-json-img>
            </el-form-item>
          </el-tab-pane>
        </el-tabs>

      </el-form>
      <span slot="footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="50%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column header-align="center" label="类" prop="legalType" width="60">
          <template slot-scope="scope">
            <el-tag v-if="scope.row.legalType">公</el-tag>
            <el-tag v-else type="success">私</el-tag>
          </template>
        </el-table-column>
        <el-table-column header-align="center" label="开户信息" prop="legalName">
          <template slot-scope="scope">
            开户名:{{scope.row.bankAccoutName}},
            开户帐号:{{scope.row.bankCode}},
            开户地区:<span v-if="scope.row.bankState.province">{{scope.row.bankState.provinceName}}</span>
            <span v-if="scope.row.bankState.city">/{{scope.row.bankState.cityName}}</span><br />
            开户银行:{{scope.row.bankName}}-支行:{{scope.row.bankBranchName}},
          </template>
        </el-table-column>
        <el-table-column header-align="center" label="状态" width="120">
          <template slot-scope="scope">
            <span style="margin-left:5px">
              <i class="fa fa-weixin" style="color:#409EFF;font-size:16px;" title="微信认证通过" v-if="scope.row.passWx"></i>
              <i class="fa fa-weixin" style="font-size:16px;" title="微信未认证" v-else></i>
            </span>
            <span style="margin-left:5px">
              <el-image v-if="scope.row.passAlipay" src="icon/svg/ali.svg" style="width:16px;height:16px;" title="支付宝认证通过"></el-image>
              <el-image v-else src="icon/svg/ali2.svg" style="width:16px;height:16px;" title="支付宝未认证"></el-image>
            </span>
            <span style="margin-left:5px">
              <i class="fa fa-lock" v-if="scope.row.isPublic" style="color:#409EFF;font-size:20px;" title="已审核通过"></i>
              <i v-else class="fa fa-unlock-alt" style="font-size:20px;" title="未审核通过"></i>
            </span>
          </template>
        </el-table-column>
      </el-table>
      <div style="width:100%;height:20px;"></div>
      <el-row :gutter="20" v-if="formChooseDialogParam.action2">
        <el-col :span="3" style="text-align:right;line-height:24px;">修改状态</el-col>
        <el-col :span="7"><el-switch v-model="formChooseDialogParam.passWx" active-text="微信认证通过" inactive-text="微信未认证"></el-switch></el-col>
        <el-col :span="7"><el-switch v-model="formChooseDialogParam.passAlipay" active-text="支付宝认证通过" inactive-text="支付宝未认证"></el-switch></el-col>
        <el-col :span="7"><el-switch v-model="formChooseDialogParam.isPublic" active-text="审核通过" inactive-text="审核未通过"></el-switch></el-col>
      </el-row>
      <span slot="footer">
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>
import FileView from '@/components/utils/FileView.vue';
import UploadJsonImg from '@/components/utils/form/UploadJsonImg.vue';
import store from '@/store';
import { getBizKind, getAreaAll } from '@/api/utils/BizUtils'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响


import { getTableData, doNew, doModify, doDelete, doPermit } from '@/api/biz/ElecShareAccount'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { required, phone, len, email } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  components: { FileView, UploadJsonImg },
  data () {
    return {
      /*导航栏状态 */
      direcParam: {
        'directName': '名称名称名称名称名称名称名称名称名称ElecShareAccount'
      },
      banks: [
        { value: "中国工商银行" },
        { value: "中国农业银行" },
        { value: "中国银行" },
        { value: "中国建设银行" },
        { value: "交通银行" },
        { value: "中国邮政储蓄银行" },
        { value: "招商银行" },
        { value: "中信银行" },
        { value: "中国民生银行" },
        { value: "浦发银行" },
        { value: "兴业银行" },
        { value: "华夏银行" },
        { value: "平安银行" },
        { value: "广发银行" },
        { value: "光大银行" },
        { value: "北京银行" },
        { value: "上海银行" },
        { value: "江苏银行" },
        { value: "宁波银行" },
        { value: "南京银行" },
        { value: "杭州银行" },
        { value: "浙商银行" },
        { value: "恒丰银行" },
        { value: "渤海银行" },
        { value: "徽商银行" },
        { value: "天津银行" },
        { value: "重庆银行" },
        { value: "成都银行" },
        { value: "长沙银行" },
        { value: "青岛银行" },
        { value: "西安银行" },
        { value: "哈尔滨银行" },
        { value: "桂林银行" },
        { value: "盛京银行" },
        { value: "大连银行" },
        { value: "包商银行" },
        { value: "晋商银行" },
        { value: "厦门国际银行" },
        { value: "苏州银行" },
        { value: "齐鲁银行" },
        { value: "东莞银行" },
        { value: "河北银行" },
      ],
      restaurants: [],
      allProvince: [], //所有省
      allCity: [], //所有市
      allArea: [],//所有的区
      allBankCity: [], //所有银行的市

      allKindOne: [],
      allKindTwo: [],
      allKindThree: [],
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'tableKey': 0,
        'searchType': 'normal'
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '50%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: '',
        isPublic: false,
        passWx: false,
        passAlipay: false,
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        legalType: false,
        linkMan: '',
        areaLimt: {
          province: '',
          provinceName: '',
          city: '',
          cityName: '',
          area: '',
          areaName: '',
        },
        linkMobile: '',
        wxId: '',
        legalName: '',
        legalID: '',
        legalAddress: '',
        legalCmpName: '',
        legalCode: '',
        scopeInfo: {
          oneId: '',
          oneName: '',
          twoId: '',
          twoName: '',
          threeId: '',
          threeName: '',
          mcc: '',
          address: '',
        },
        proveFile: [],
        siteFile: [],
        bankAccoutName: '',
        bankCode: '',
        bankState: {
          province: '',
          provinceName: '',
          city: '',
          cityName: '',
        },
        bankName: '',
        bankBranchName: '',
        passWx: false,
        passAlipay: false,
        isPublic: false,
      },
      formDialogRules:
      {
        legalType: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        linkMan: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        areaLimt: {
          province: [required],
          city: [required],
          area: [required],
        },
        linkMobile: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        wxId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        legalName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        legalID: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        legalAddress: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        legalCmpName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        legalCode: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        scopeInfo: {
          oneId: [required],
          twoId: [required],
          threeId: [required],
          address: [required],
          mcc: [required],
        },//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        proveFile: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        siteFile: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        bankAccoutName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        bankCode: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        bankState: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        bankCity: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        bankName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        bankBranchName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
    }
  },
  methods: {
    changeProvince (provinceCode) {
      const selectedOption = this.allProvince.find(item => item.id === provinceCode);
      this.formData.areaLimt.provinceName = selectedOption.name;
      getAreaAll({ parentId: provinceCode }).then(response => {
        if (response.data.success) {
          this.allCity = response.data.data;
          let index = this.allCity.findIndex(item => item.id == this.formData.areaLimt.city); //是否存在
          if (!(index >= 0)) {
            this.formData.areaLimt.city = '';
            this.formData.areaLimt.cityName = '';
            this.formData.areaLimt.area = '';
            this.formData.areaLimt.areaName = '';
          }
          else {
            this.changeCity(this.formData.areaLimt.city)
          }
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },
    changeCity (cityCode) {
      const selectedOption = this.allCity.find(item => item.id === cityCode);
      this.formData.areaLimt.cityName = selectedOption.name;
      getAreaAll({ parentId: cityCode }).then(response => {
        if (response.data.success) {
          this.allArea = response.data.data;
          let index = this.allArea.findIndex(item => item.id == this.formData.areaLimt.area); //是否存在
          if (!(index >= 0)) {
            this.formData.areaLimt.area = '';
            this.formData.areaLimt.areaName = '';
          }
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },
    changeArea (areaCode) {
      const selectedOption = this.allArea.find(item => item.id === areaCode);
      this.formData.areaLimt.areaName = selectedOption.name;
    },
    //银行只改变两个乘级  
    changeBankProvince (provinceCode) {
      const selectedOption = this.allProvince.find(item => item.id === provinceCode);
      this.formData.bankState.provinceName = selectedOption.name;
      getAreaAll({ parentId: provinceCode }).then(response => {
        if (response.data.success) {
          this.allBankCity = response.data.data;
          let index = this.allBankCity.findIndex(item => item.id == this.formData.bankState.city); //是否存在
          if (!(index >= 0)) {
            this.formData.bankState.city = '';
            this.formData.bankState.cityName = '';
          }
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },
    changeBankCity (areaCode) {
      const selectedOption = this.allBankCity.find(item => item.id === areaCode);
      this.formData.bankState.city = areaCode;
      this.formData.bankState.cityName = selectedOption.name;
    },
    //超前搜索,匹配正确的银行



    querySearch (queryString, cb) {
      var tempBanks = this.banks;
      var results = queryString ? tempBanks.filter(this.createBankFilter(queryString)) : tempBanks;
      // 调用 callback 返回建议列表的数据
      cb(results);
    },
    createBankFilter (queryString) {
      return (bank) => {
        return (bank.value.indexOf(queryString) >= 0);
      };
    },
    /** 经营范围 */
    changeBizKindOne (parentIdParam) {
      const selectedOption = this.allKindOne.find(item => item.id === parentIdParam);
      this.formData.scopeInfo.oneName = selectedOption.name;
      getBizKind({ parentId: parentIdParam }).then(response => {
        if (response.data.success) {
          this.allKindTwo = response.data.data;
          let index = this.allKindTwo.findIndex(item => item.id == this.formData.scopeInfo.twoId); //是否存在
          if (!(index >= 0)) {
            this.formData.scopeInfo.twoId = '';
            this.formData.scopeInfo.twoName = '';
            this.formData.scopeInfo.threeId = '';
            this.formData.scopeInfo.threeName = '';
          }
          else {
            this.changeBizKindTwo(this.formData.scopeInfo.twoId)
          }
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },
    changeBizKindTwo (parentIdParam) {
      const selectedOption = this.allKindTwo.find(item => item.id === parentIdParam);
      this.formData.scopeInfo.twoName = selectedOption.name;
      getBizKind({ parentId: parentIdParam }).then(response => {
        if (response.data.success) {
          this.allKindThree = response.data.data;
          let index = this.allKindThree.findIndex(item => item.id == this.formData.scopeInfo.threeId); //是否存在
          if (!(index >= 0)) {
            this.formData.scopeInfo.threeId = '';
            this.formData.scopeInfo.threeName = '';
            this.formData.scopeInfo.mcc = '';
          }
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },
    changeBizKindThree (parentIdParam) {
      const selectedOption = this.allKindThree.find(item => item.id === parentIdParam);
      this.formData.scopeInfo.threeName = selectedOption.name;
      this.formData.scopeInfo.mcc = selectedOption.mcc;
    },

    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        if (this.formData.areaLimt.province) {
          this.changeProvince(this.formData.areaLimt.province);
        }
        if (this.formData.scopeInfo.oneId) {
          this.changeBizKindOne(this.formData.scopeInfo.oneId);
        }
        if (this.formData.bankState.province) {
          this.changeBankProvince(this.formData.bankState.province);
        }
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定的记录(请谨慎操作)"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    /*表单(form)-审核  */
    formDataPermit () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能审核");
      }
      else {
        this.formChooseDialogParam.title = "将选定的记录,审核/取消审核"
        this.formChooseDialogParam.formChooseAction = 'permit';
        this.formChooseDialogParam.action1 = false;
        this.formChooseDialogParam.action1View = '取 消';
        this.formChooseDialogParam.action2 = true;
        this.formChooseDialogParam.action2View = '确 定';
        this.formChooseDialogParam.visible = true;
        this.formChooseDialogParam.transSelectVisible = false;
      }
    },
    formDataValidate () {
      let result = true;
      this.$refs.elecShareAccountFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.formData));
      postData.scopeInfo = JSON.stringify(this.formData.scopeInfo);//由于MysqlJson的原因
      postData.proveFile = JSON.stringify(this.formData.proveFile);//由于MysqlJson的原因
      postData.siteFile = JSON.stringify(this.formData.siteFile);//由于MysqlJson的原因
      postData.areaLimt = JSON.stringify(this.formData.areaLimt);//由于MysqlJson的原因
      postData.bankState = JSON.stringify(this.formData.bankState);//由于MysqlJson的原因
      //postData.classId = this.tableParam.classId;
      //postData.imageUrl = JSON.stringify(this.formData.imageUrl);//由于MysqlJson的原因
      //postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因
      //doNew(this.formData).then(response => {
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    formChooseSubmit () {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
      else if (this.formChooseDialogParam.formChooseAction == 'permit') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          canId.push(this.tableMultipleSelection[i].id);
        }
        const data = new FormData()
        data.append('id', canId);
        data.append('isPublic', this.formChooseDialogParam.isPublic);
        data.append('passWx', this.formChooseDialogParam.passWx);
        data.append('passAlipay', this.formChooseDialogParam.passAlipay);
        doPermit(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      this.$refs.elecShareAccountTableRef.clearSelection(); //清除选中
      this.$refs.elecShareAccountTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },
    scopeInfoSuccessUpload (data) { //上传scopeInfo成功,更新数据
      this.formData.scopeInfo = data;
    },

    proveFileSuccessUpload (data) { //上传proveFile成功,更新数据
      this.formData.proveFile = data;
    },

    siteFileSuccessUpload (data) { //上传siteFile成功,更新数据
      this.formData.siteFile = data;
    },


  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  filters: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.direcParam = JSON.parse(store.getters.getActiveCus());
    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    getAreaAll({ parentId: 10000000 }).then(response => {
      if (response.data.success) {
        this.allProvince = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    getBizKind({ parentId: 0 }).then(response => {
      if (response.data.success) {
        this.allKindOne = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });


    this.tableDateRefresh();
  }
}
</script>
