<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="19">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="5">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" style="width:100%" @change="tableChangeSearchKey">
            <el-button type="primary" slot="append"><i class="fa fa-search" style="color:#409EFF"></i></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-button-group>
          <el-button @click="formDataNew" type="primary">新建</el-button>
          <el-button @click="formDataModify" type="primary">修改</el-button>
          <el-button @click="formDataDelete" type="primary">删除</el-button>
        </el-button-group>
      </el-col>
    </el-row>

    <el-table ref="elecAreaTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" label="ID" prop="id" width="80"></el-table-column>
      <el-table-column header-align="center" label="小区名称" prop="areaName" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" label="行政区域" prop="areaLimt" width="320">
        <template slot-scope="scope">
          {{scope.row.areaLimt.provinceName}}/{{scope.row.areaLimt.cityName}}/{{scope.row.areaLimt.areaName}} ({{scope.row.areaCode}})
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="小区联系人" prop="linkMan" show-overflow-tooltip>
        <template slot-scope="scope">
          {{scope.row.linkMan}} {{scope.row.linkPhone}}
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="场地类型" prop="siteType" width="120">
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.siteType)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="结算" prop="countType" width="90">
        <template slot-scope="scope">
          <el-tooltip placement="top" v-if="scope.row.canCash" content="结算后打款">
            <el-tag>T+{{scope.row.countType}} 款</el-tag>
          </el-tooltip>
          <el-tooltip placement="top" v-else content="仅结算不打款">
            <el-tag type="success">T+{{scope.row.countType}} 结</el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="分成设置" prop="shareSetting">
        <template slot-scope="scope">
          <el-tooltip v-if="scope.row.shareSetting.length==0">
            <div slot="content" style="line-height:20px;">
              平台完全分成100%<br />
            </div>
            <el-tag type="info">100%</el-tag>
          </el-tooltip>
          <el-tooltip placement="top" v-for="(item,index) in scope.row.shareSetting" :key="'shareAccount'+index">
            <div slot="content" style="line-height:20px;">
              显名:{{item.accountJson.linkMan}}&nbsp;&nbsp;<span v-if="item.accountJson.legalType==1"
                style="background-color:#409EFF;color: #ffffff;padding:3px;">对公</span>
              <span v-else style="background-color:#67C23A;color:#ffffff;padding:3px;">对私</span>&nbsp;&nbsp;
              <span v-if="item.isMain" style="background-color:#409EFF;color: #ffffff;padding:3px;">主账号</span>
              <span v-else style="background-color:#67C23A;color:#ffffff;padding:3px;">分享账号</span><br>
              联系电话:{{item.accountJson.linkMobile}}<br />
              开户名:{{item.accountJson.bankAccoutName}}<br />
              开户账号:{{item.accountJson.bankCode}}<br />
              开户行:{{item.accountJson.bankName}}<br />
              开户支行:{{item.accountJson.bankBranchName}}<br />
            </div>
            <el-tag style="margin-left:5px;" v-if="item.isMain">{{item.sharePercent}}%</el-tag>
            <el-tag style="margin-left:5px;" v-else type="success">{{item.sharePercent}}%</el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="创建时间" prop="createTime" width="140"></el-table-column>
      <el-table-column header-align="center" label="合约" width="100" prop="isPublic" align="center">
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <div slot="content" style="line-height:20px;">
              合约日期:{{ scope.row.endDate }}<br />
            </div>
            <el-tag v-if="compareDate(scope.row.isPublic,scope.row.endDate)">合约正常</el-tag>
            <el-tag v-else type="warning">合约异常</el-tag>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="人员" width="100" align="center">
        <template slot-scope="scope">
          <el-button type="success" size="mini" @click="areaAdminShow(scope.row.id,scope.row.areaName)">设定</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>

    <!--编辑的表单-->

    <el-dialog v-dialogDrag :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width" :top="formDialogParam.top"
      :close-on-click-modal='false'>
      <el-form ref="elecAreaFormRef" :model="formData" :rules="formDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-row style="padding:0px;" :gutter="20">
          <el-col :span="8">
            <el-form-item label="小区名称" prop="areaName">
              <el-input id="areaName" v-model="formData.areaName" placeholder="请输入小区名称" maxlength="100" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="行政区域" prop="areaLimt.province">
              <el-select v-model="formData.areaLimt.province" filterable placeholder="选择省份" @change="changeProvince" style="width:100%">
                <el-option v-for="item in this.allProvince" :key="'allProvince'+item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系人" prop="linkMan">
              <el-input id="linkMan" v-model="formData.linkMan" placeholder="请输入联系人" maxlength="10" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="城市" prop="areaLimt.city">
              <el-select v-model="formData.areaLimt.city" filterable placeholder="选择城市" @change="changeCity" style="width:100%">
                <el-option v-for="item in this.allCity" :key="'allCity'+item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="联系电话" prop="linkPhone">
              <el-input id="linkPhone" v-model="formData.linkPhone" placeholder="请输入联系电话" maxlength="12" show-word-limit></el-input>
            </el-form-item>
            <el-form-item label="区县" prop="areaLimt.area">
              <el-select v-model="formData.areaLimt.area" filterable placeholder="选择区县" @change="changeArea" style="width:100%">
                <el-option v-for="item in this.allArea" :key="'allArea'+item.id" :label="item.name" :value="item.id">
                </el-option>
              </el-select>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="padding:0px;" :gutter="20">
          <el-col :span="8">
            <el-form-item label="区域编码" prop="areaCode">
              <el-input id="areaCode" v-model="formData.areaCode" placeholder="请输入区域编码" maxlength="10" show-word-limit></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="16">
            <el-form-item label="小区地址" prop="areaAddress">
              <el-input id="areaAddress" v-model="formData.areaAddress" placeholder="请输入小区地址(请不要填入省-市-区县)" maxlength="100" show-word-limit></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="padding:0px;" :gutter="20">
          <el-col :span="8">
            <el-form-item label="场地类型" prop="siteType">
              <el-select v-model="formData.siteType" filterable placeholder="选择场地类型" style="width:100%">
                <el-option v-for="(name, id) in allAreaTypeInfo" :key="id" :label="name" :value="name"></el-option>
              </el-select>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="结算方式" prop="countType">
              <el-input id="countType" v-model="formData.countType" type="number" :min="1" :max="1000" placeholder="结算方式">
                <template slot="append"><span style="color:#67C23A">T+{{formData.countType}}</span>&nbsp;订单完成{{formData.countType}}天后,按[分成设置]结算</template>
              </el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="padding:0px;" :gutter="20">
          <el-col :span="8">
            <el-form-item label="结算打款">
              <el-checkbox v-model="formData.canCash">启用</el-checkbox>
              <el-tooltip placement="top">
                <div slot="content" style="line-height:20px;" effect="light">
                  启用后,会在结算完成后打款;<br />
                  未启用,系统将不会给分成人员打款;<br />
                </div>
                <i class="el-icon-question" style="font-size:16px;color: #67C23A;margin-left: 20px;"></i>
              </el-tooltip>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="小区合约">
              <el-radio-group v-model="formData.isPublic">
                <el-radio :label="true">已签约</el-radio>
                <el-radio :label="false">未签约</el-radio>
              </el-radio-group>
            </el-form-item>
          </el-col>
          <el-col :span="8">
            <el-form-item label="合作期限" prop="endDate" v-if="formData.isPublic">
              <el-date-picker id="endDate" v-model="formData.endDate" value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date"
                placeholder="请选择合作期限"></el-date-picker>
            </el-form-item>
          </el-col>
        </el-row>

        <el-form-item label="分成设置" prop="shareSetting" style="margin-bottom:0px;"><span style="color: #409EFF;">*如果不设置,默认为平台100%分成</span>
          <table-share-setting id="shareSetting" :jsonArray="formData.shareSetting" @success="shareSettingSuccess"></table-share-setting>
        </el-form-item>
        <el-form-item label="充电参数" style="margin-bottom:0px;">
          <el-checkbox v-model="formData.setInfo.useDefault" @change="changeUseDefault">使用 系统平台默认参数</el-checkbox>
        </el-form-item>
        <el-card v-if="!formData.setInfo.useDefault" style="width:82%;margin: 0 auto;">
          <el-row style="padding:0px;" :gutter="10">
            <el-col :span="12">
              <el-form-item label="空载断电时长" prop="setInfo.elecNullSecond" label-width="110" size="mini">
                <el-input v-model="formData.setInfo.elecNullSecond" placeholder="请输入空载断电时长" type="number" step="0" :min="30" :max="240" style="width:50%">
                  <template slot="append">秒</template></el-input>
              </el-form-item>
              <el-form-item label="充满自停功率" prop="setInfo.elecFullPower" label-width="110" size="mini">
                <el-input v-model="formData.setInfo.elecFullPower" placeholder="请输入设备充满自停功率" type="number" :min="10" :max="1000" style="width:50%">
                  <template slot="append">瓦</template></el-input>
              </el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="允许最大功率" prop="setInfo.elecMaxPower" label-width="110" size="mini">
                <el-input v-model="formData.setInfo.elecMaxPower" placeholder="请输入允许最大功率" type="number" :min="10" :max="10000" style="width:50%">
                  <template slot="append">瓦</template></el-input>
              </el-form-item>
              <el-form-item label="充满等待时间" prop="setInfo.elecWaitSecond" label-width="110" size="mini">
                <el-input v-model="formData.setInfo.elecWaitSecond" placeholder="请输入设备充满等待时间" type="number" :min="10" :max="10000" style="width:50%">
                  <template slot="append">秒</template></el-input>
              </el-form-item>
            </el-col>
          </el-row>
        </el-card>
      </el-form>
      <span slot="footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="50%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column header-align="center" label="小区名称" prop="areaName" show-overflow-tooltip></el-table-column>
        <el-table-column header-align="center" label="行政区域" prop="areaLimt" width="320">
          <template slot-scope="scope">
            {{scope.row.areaLimt.provinceName}}/{{scope.row.areaLimt.cityName}}/{{scope.row.areaLimt.areaName}} ({{scope.row.areaCode}})
          </template>
        </el-table-column>
      </el-table>
      <span slot="footer">
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag title="人员设定" :visible.sync="areaAdminDialogParam.visible" width="50%" :close-on-click-modal='false'>
      <area-admin-set :areaId="areaAdminDialogParam.areaId" :areaName="areaAdminDialogParam.areaName"></area-admin-set>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>
import TableShareSetting from '@/components/elecArea/TableShareSetting.vue';
import AreaAdminSet from '@/components/elecArea/AreaAdminSet.vue';
import dayjs from 'dayjs';
import copy from 'fast-copy';
import store from '@/store';
import { getSetting } from '@/api/wbkj/Setting';
import { getAreaAll, getAreaTypeInfo } from '@/api/utils/BizUtils';
import { getTableData, doNew, doModify, doDelete, } from '@/api/biz/ElecArea'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { required, phone, len, email } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  components: { TableShareSetting, AreaAdminSet },
  data () {
    return {
      /*导航栏状态 */
      direcParam: {
        'directName': '名称名称名称名称名称名称名称名称名称ElecArea'
      },
      defaultSysSet: {}, //系统默认设定
      allAreaTypeInfo: {}, //场地类型
      allProvince: [], //所有省
      allCity: [], //所有市
      allArea: [],//所有的区
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'tableKey': 0,
        'searchType': 'normal'
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '50%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: ''
      },
      areaAdminDialogParam:
      {
        visible: false,
        areaId: 0,
        areaName: ''
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        areaCode: '',
        areaLimt: {
          province: '',
          provinceName: '',
          city: '',
          cityName: '',
          area: '',
          areaName: '',
        },
        areaName: '',
        areaAddress: '',
        siteType: '',
        countType: 1,
        canCash: true,
        shareSetting: [],
        createTime: '',
        linkMan: '',
        linkPhone: '',
        isPublic: true,
        endDate: new dayjs(new Date()).add(1, 'year').format('YYYY-MM-DD'),
        setDefault: true,
        setInfo: {
          useDefault: true,
          elecNullSecond: '',
          elecFullPower: '',
          elecMaxPower: '',
          elecWaitSecond: '',
        },

      },
      formDialogRules:
      {
        areaCode: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        areaLimt: {
          province: [required],
          city: [required],
          area: [required],
        },
        areaName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        siteType: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        countType: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        canCash: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        createTime: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        endDate: [required],
        setInfo: {
          elecNullSecond: [required],
          elecFullPower: [required],
          elecMaxPower: [required],
          elecWaitSecond: [required]
        }
      },
    }
  },
  methods: {
    changeUseDefault (checkValue) {
      if (checkValue) //如果是已签约
      {
        this.formData.setInfo = copy(this.defaultSysSet);
        this.$set(this.formData.setInfo, 'useDefault', true);
      }
    },
    areaAdminShow (areaId, areaName) {
      this.areaAdminDialogParam.areaId = areaId;
      this.areaAdminDialogParam.areaName = areaName;
      this.areaAdminDialogParam.visible = true;
    },
    compareDate (isPublic, endDate) {
      if (isPublic) //如果是已签约
      {
        return new dayjs(endDate).isAfter(new Date())
      }
      else {
        return false;
      }
    },
    changeProvince (provinceCode) {
      const selectedOption = this.allProvince.find(item => item.id === provinceCode);
      this.formData.areaLimt.provinceName = selectedOption.name;
      getAreaAll({ parentId: provinceCode }).then(response => {
        if (response.data.success) {
          this.allCity = response.data.data;
          let index = this.allCity.findIndex(item => item.id == this.formData.areaLimt.city); //是否存在
          if (!(index >= 0)) {
            this.formData.areaLimt.city = '';
            this.formData.areaLimt.cityName = '';
            this.formData.areaLimt.area = '';
            this.formData.areaLimt.areaName = '';
          }
          else {
            this.changeCity(this.formData.areaLimt.city)
          }
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },
    changeCity (cityCode) {
      const selectedOption = this.allCity.find(item => item.id === cityCode);
      this.formData.areaLimt.cityName = selectedOption.name;
      getAreaAll({ parentId: cityCode }).then(response => {
        if (response.data.success) {
          this.allArea = response.data.data;
          let index = this.allArea.findIndex(item => item.id == this.formData.areaLimt.area); //是否存在
          if (!(index >= 0)) {
            this.formData.areaLimt.area = '';
            this.formData.areaLimt.areaName = '';
          }
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },
    changeArea (areaCode) {
      const selectedOption = this.allArea.find(item => item.id === areaCode);
      this.formData.areaLimt.areaName = selectedOption.name;
      this.formData.areaCode = selectedOption.code;
    },
    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.formData.setInfo = copy(this.defaultSysSet);
      this.$set(this.formData.setInfo, 'useDefault', true);

      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        if (!(this.formData.setInfo) || !(this.formData.setInfo.elecFullPower)) {
          this.formData.setInfo = copy(this.defaultSysSet);
          this.$set(this.formData.setInfo, 'useDefault', true);//使用系统默认值
        }
        console.log(this.formData.setInfo);
        if (this.formData.areaLimt.province) {
          this.changeProvince(this.formData.areaLimt.province);
        }
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定(已绑定设备的小区不能删除)"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    formDataValidate () {
      let result = true;
      this.$refs.elecAreaFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    validateRecords (records) {
      if (records.length == 0) {
        return {
          valid: true,
          message: '验证通过'
        };
      }
      let mainCount = 0;
      let shareSum = 0;
      let bankCodes = new Set();
      for (const record of records) {
        if (record.isMain) {
          mainCount++;
        }
        shareSum += parseFloat(record.sharePercent);

        if (bankCodes.has(record.accountJson.bankCode)) {
          return { valid: false, message: record.accountJson.linkMan + ',银行账号[' + record.accountJson.bankCode + ']重复,请修改后再提效' };
        }
        bankCodes.add(record.accountJson.bankCode);
      }
      const isValidMain = mainCount === 1;
      const isValidShare = shareSum === 100;

      const message = !isValidMain ? '有且只能有一个主收款账号' : '';
      const shareMessage = !isValidShare ? `分成比例总和为${shareSum}%, 正确的应该是100%` : '';

      return {
        valid: isValidMain && isValidShare,
        message: (message && shareMessage) ? `${message} ${shareMessage}` : message || shareMessage || '验证通过'
      };
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.formData));
      postData.shareSetting = JSON.stringify(this.formData.shareSetting);//由于MysqlJson的原因
      postData.areaLimt = JSON.stringify(this.formData.areaLimt);//由于MysqlJson的原因
      postData.setInfo = JSON.stringify(this.formData.setInfo);
      const result = this.validateRecords(this.formData.shareSetting);
      if (!result.valid) {
        this.notifyWarning(result.message);
        this.formDialogParam.visible = true;
        return;
      }
      //postData.classId = this.tableParam.classId;
      //postData.imageUrl = JSON.stringify(this.formData.imageUrl);//由于MysqlJson的原因
      //postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因
      //doNew(this.formData).then(response => {
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    formChooseSubmit () {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          canId.push(this.tableMultipleSelection[i].id);
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      this.$refs.elecAreaTableRef.clearSelection(); //清除选中
      this.$refs.elecAreaTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },
    shareSettingSuccess (data) { //JsonArray,数据回传shareSetting成功,更新数据
      this.formData.shareSetting = data;
    },


  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  filters: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.direcParam = JSON.parse(store.getters.getActiveCus());
    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    getAreaAll({ parentId: 10000000 }).then(response => {
      if (response.data.success) {
        this.allProvince = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    getAreaTypeInfo().then(response => {
      if (response.data.success) {
        this.allAreaTypeInfo = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
    /*得到充电的默认参数 */
    getSetting().then(response => {
      if (response.data.success) {
        this.defaultSysSet = response.data.data;
        this.$delete(this.defaultSysSet, 'pathUrl');
        this.$delete(this.defaultSysSet, 'siteSub');
        this.$delete(this.defaultSysSet, 'sysName');
        this.$delete(this.defaultSysSet, 'siteTitle');
        this.$delete(this.defaultSysSet, 'pathEnable');
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    })
    this.tableDateRefresh();
  }
}
</script>
