import { httpJson } from '@/utils/adminRequest'
export const getQrCode = (param) => {
    return httpJson.get('/setting/createQr', { 'params': param })
}

export const getDevQrCode = (param) => {
    return httpJson.get('/setting/createDevQr', { 'params': param })
}


