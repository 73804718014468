import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个ElecArea接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/elecArea/list', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/elecArea/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/elecArea/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/elecArea/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/elecArea/delete', formData)
}
//小区管理者设定
export const getAdminTableData = (tableParam) => {
    return httpJson.get('/areaAdmin/list', { 'params': tableParam })
}
export const doAdminNew = (formData) => {
    return httpForm.post('/areaAdmin/new', formData)
}
export const doAdminModify = (formData) => {
    return httpForm.post('/areaAdmin/modify', formData)
}
export const doAdminDelete = (formData) => {
    return httpForm.post('/areaAdmin/delete', formData)
}
export const getAllCusAdminData = () => {
    return httpJson.get('/admin/cusList')
}


