<template>
  <span>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span><i class="el-icon-coin" style="color:#67C23A"></i> 磁盘存储</span>
        <router-link :to="{name: 'backUp'}"><el-button type="text" style="padding: 0 3px;float: right;">备份管理</el-button></router-link>
      </div>
      <div class="text item" style="font-size: 14px;">
        <el-row :gutter="20">
          <el-col :span="3">盘符</el-col>
          <el-col :span="3" style="text-align:right;">类型</el-col>
          <el-col :span="6" style="text-align:center;">大小</el-col>
          <el-col :span="12" style="text-align:center;">占比</el-col>
        </el-row>
        <el-row :gutter="20" v-for="(item,index) in this.diskInfo" :key="'diskInfo'+index">
          <el-col :span="3">
            {{item.diskDirName}}</el-col>
          <el-col :span="3" style="text-align:right;">
            {{item.diskSysTypeName}}</el-col>
          <el-col :span="6" style="text-align:right;">{{(item.diskUsed/1024).toFixed(2)}}/
            {{(item.diskTotal/1024).toFixed(2)}}G</el-col>
          <el-col :span="12">
            <el-progress :text-inside="true" :stroke-width="18" :color="diskColors" :percentage="countPercentage(item.diskUsed,item.diskTotal)"></el-progress>
          </el-col>
        </el-row>
      </div>
    </el-card>
  </span>
</template>
 <script> 
import { getDiskInfo } from '@/api/wbkj/Setting.js';
export default {
  data () {
    return {
      diskInfo: {},
    }
  },
  mounted () {
    getDiskInfo().then(response => {
      if (response.data.success) {
        this.diskInfo = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    })
  },
}
</script>