import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个ElecUsers接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/elecUsers/list', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/elecUsers/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/elecUsers/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/elecUsers/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/elecUsers/delete', formData)
}


