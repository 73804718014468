import Vue from 'vue';
import App from './App.vue';
import ElementUI from 'element-ui';
import 'element-ui/lib/theme-chalk/index.css';
import '@/utils/dialogDrag'; //窗口拖动
import 'font-awesome/css/font-awesome.min.css';//图标调用
import router from './router/allRouter' //必须那边声明 export default后才可以调用
import store from './store' //必须那边声明 export default后才可以调用
import * as echarts from 'echarts';
import { notifyInfo, notifySuccess, notifyWarning, notifyError, downloadFile, countPercentage, numView, checkCusRight, checkDocRight, formatTooltipContent } from '@/utils/methods'; //引入全局方法
import { sizeFilter, numFilter, nullNumFilter, rateFilter, cmpFilter, saleNameFilter, seasonFilter, dateFilter, dateTimeFilter, replaceNewline, yuanFilter } from '@/utils/filters'; //引入全局过滤器
/*全局过滤器 */
Vue.filter('sizeFilter', sizeFilter);//引入文件大小过滤
Vue.filter('numFilter', numFilter);//引入数值万数计数
Vue.filter('nullNumFilter', nullNumFilter);//非空数字计数
Vue.filter('rateFilter', rateFilter);//引入税率过滤
Vue.filter('cmpFilter', cmpFilter);//引入公司名称过滤
Vue.filter('saleNameFilter', saleNameFilter);//销售名称过滤
Vue.filter('seasonFilter', seasonFilter);//跟据时间日期得到季节过滤
Vue.filter('dateFilter', dateFilter);//引入日期格式化
Vue.filter('dateTimeFilter', dateTimeFilter);//引入日期格式化
Vue.filter('replaceNewline', replaceNewline);//引入行格式化
Vue.filter('yuanFilter', yuanFilter);//引入分到元格式化

/*全局过滤器 */

/*全局方法挂载*/
Vue.prototype.notifyInfo = notifyInfo;
Vue.prototype.notifySuccess = notifySuccess;
Vue.prototype.notifyWarning = notifyWarning;
Vue.prototype.notifyError = notifyError;
Vue.prototype.downloadFile = downloadFile;
Vue.prototype.countPercentage = countPercentage;
Vue.prototype.numView = numView;
Vue.prototype.checkCusRight = checkCusRight; //查询权限,
Vue.prototype.checkDocRight = checkDocRight; //查询特定权限,
Vue.prototype.formatTooltipContent = formatTooltipContent; //格式化内容,使 \n 变成<br>,






/*全局方法挂载*/
Date.prototype.Format = function (fmt) {
  var o = {
    'M+': this.getMonth() + 1, // 月份
    'd+': this.getDate(), // 日
    'h+': this.getHours(), // 小时
    'm+': this.getMinutes(), // 分
    's+': this.getSeconds(), // 秒
    'q+': Math.floor((this.getMonth() + 3) / 3), // 季度
    'S': this.getMilliseconds() // 毫秒
  }
  if (/(y+)/.test(fmt)) { fmt = fmt.replace(RegExp.$1, (this.getFullYear() + '').substr(4 - RegExp.$1.length)) }
  for (var k in o) {
    if (new RegExp('(' + k + ')').test(fmt)) { fmt = fmt.replace(RegExp.$1, (RegExp.$1.length === 1) ? (o[k]) : (('00' + o[k]).substr(('' + o[k]).length))) }
  }
  return fmt
}
//import './api/mock'  //调用数据模拟,一般情况下是不需要的
Vue.use(ElementUI);
Vue.prototype.$echarts = echarts //引入图表
/*设置全局Axios */
new Vue({
  router,//挂进router
  store,
  render: h => h(App),
}).$mount('#app')
