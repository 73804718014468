<template>
  <div>
    <el-table :data="shareSetting" height="150" border :cell-style="{height:'24px',padding:0,margin:0}" size="mini">
      <el-table-column prop="accountJson" label="显名" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-if="scope.row.accountJson">{{scope.row.accountJson.linkMan}}</span>
        </template>
      </el-table-column>
      <el-table-column prop="isMain" label="类型" width="80">
        <template slot-scope="scope">

          <el-tag v-if="scope.row.isMain" size="mini">主</el-tag>
          <el-tag v-else type="success" size="mini">享</el-tag>
        </template>
      </el-table-column>
      <el-table-column prop="sharePercent" label="分成比例" width="80">
        <template slot-scope="scope">
          {{scope.row.sharePercent}}%
        </template>
      </el-table-column>
      <el-table-column prop="note" label="备注" width="80"></el-table-column>
      <el-table-column label="操作" width="100">
        <template slot="header">
          <el-button type="success" icon="el-icon-plus" size="mini" @click="shareSettingNew()">新建</el-button>
        </template>
        <template slot-scope="scope">
          <el-button @click="shareSettingEdit(scope.row)" type="text" size="small">修改</el-button>
          <el-button @click="shareSettingDelete(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-dialogDrag :visible.sync="shareSettingDialogParam.visible" :width="shareSettingDialogParam.width" :close-on-click-modal='false' append-to-body>
      <template slot="title">
        <div style="font-size:14px;">{{shareSettingDialogParam.title}}</div>
      </template>
      <el-form :model="shareSettingData" label-width="100px" size="small" ref="shareSettingForm" :rules="shareSettingDataRules">
        <!-- 这里修改表单项 -->
        <el-form-item label="收款人" prop="accountId">
          <el-select v-model="shareSettingData.accountId" filterable placeholder="收款人帐号" ref="accountSelect" style="width:100%" @change="changeAccount">
            <el-option v-for="item in this.allShareAccount" :key="'shareAccount'+item.id"
              :label="item.id+'-'+item.linkMan+'-'+item.linkMobile+'-'+item.bankAccoutName+'-'+item.bankBranchName+'-'+item.legalName+'-'+item.legalCode"
              :value="item.id">
            </el-option>
          </el-select>
        </el-form-item>

        <el-row>
          <el-col :span="12">
            <el-form-item label="分成比例">
              <el-input v-model="shareSettingData.sharePercent" placeholder="分成比例" type="number" :min="0" :max="100"><template
                  slot="append">%</template></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="12">
            <el-form-item label="是否主账号" prop="isMain">
              <el-checkbox v-model="shareSettingData.isMain">是主账号</el-checkbox>
              <el-tooltip placement="top">
                <div slot="content" style="line-height:20px;" effect="light">
                  有且只有一个主账号<br />
                  主账号收成=服务费X分成比例+电费100%收益<br />
                  非主账号收成=服务费X分成比例<br />
                </div>
                <i class="el-icon-question" style="font-size:16px;color: #67C23A;margin-left: 20px;"></i>
              </el-tooltip>
            </el-form-item></el-col>
        </el-row>
        <el-form-item label="备注说明" prop="note">
          <el-input type="textarea" :rows="4" resize="none" placeholder="请输入备注说明" v-model="shareSettingData.note">
          </el-input>
        </el-form-item>
        <!-- 这里修改表单项 -->
      </el-form>
      <span slot="footer">
        <el-button @click="shareSettingDialogParam.visible = false">取 消</el-button>
        <el-button @click="shareSettingPush()" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
import { getShareAccount } from '@/api/utils/BizUtils';
import copy from 'fast-copy';
export default {
  props: { jsonArray: { type: Array, required: true } },
  data () {
    return {
      allShareAccount: [],
      sharePercent: 100,
      shareSetting: this.jsonArray,
      /*-------------------------------------------------json shareSetting 参数-----------------------------------------------*/
      shareSettingDialogParam: {
        visible: false,
        title: '新建',
        width: '50%',
        top: '15vh',
      },
      /*--json 字段参数--*/
      shareSettingRawData: {
        key: '',
        accountId: '',
        accountJson: {},
        isMain: true,
        sharePercent: 1,
        note: '',
      },
      shareSettingData: {
        key: '',
        accountId: '',
        accountJson: {},
        isMain: true,
        sharePercent: 1,
        note: '',
      },
      shareSettingDataRules:
      {
        isMain: [required],
        accountId: [required],
        sharePercent: [required],
      },
    };
  },
  methods: {
    /*-------------------------------------------------json shareSetting 方法-----------------------------------------------*/
    /**
     * 随机取数
     * @param {*} a
     * @param {*} b
     */
    changeAccount (countId) {
      this.$nextTick(() => {
        const selectedOption = this.allShareAccount.find(item => item.id === countId);
        this.shareSettingData.accountJson = copy(selectedOption);//主要原因是Dom更新的原因,如果直接取值,取到的是当前值
      })
    },
    cusRandom (a, b) {
      var n = Math.round(Math.random() * (a - b) + b);
      return n;
    },
    shareSettingNew () {
      this.shareSettingData = copy(this.shareSettingRawData)//重置
      let strData = "";
      //如果觉得12个数太少也可以多放点，将i<12修改即可
      for (var i = 0; i < 12; i++) {
        let num = this.cusRandom(0, 9);                        //数字
        let upper = String.fromCharCode(this.cusRandom(65, 90));     //大写字母
        let lower = String.fromCharCode(this.cusRandom(97, 122));    //小写字母
        strData = strData + num + upper + lower;                  //将所有结果放进strData中
      }
      let str = "";
      for (let i = 0; i < 12; i++) {
        str += strData[this.cusRandom(0, strData.length - 1)];         //在strData里面随机抽取四个数
      }
      this.shareSettingData.key = str; //增加一个UID的标识为了修改
      this.shareSettingDialogParam.title = "新建";
      this.shareSettingDialogParam.visible = true;
    },
    shareSettingPush () {
      this.$refs.shareSettingForm.validate((valid) => {
        if (valid) {
          let index = this.shareSetting.findIndex((item) => item.key === this.shareSettingData.key);
          if (index > -1)//表示找到了
          {
            this.shareSetting.splice(index, 1, this.shareSettingData);
          }
          else {
            this.shareSetting.push(this.shareSettingData);//插到最后面
          }
        }
        this.shareSettingDialogParam.visible = false;//不显示
        this.$emit('success', this.shareSetting);
      });
    },
    shareSettingDelete (key) {
      let index = this.shareSetting.indexOf(key);//查出文件在哪
      // eslint-disable-next-line vue/no-mutating-props
      this.shareSetting.splice(index, 1);
      this.$emit('success', this.shareSetting);
    },
    shareSettingEdit (key) {
      let index = this.shareSetting.indexOf(key);//查出文件在哪
      this.shareSettingData = this.shareSetting[index];
      this.shareSettingDialogParam.title = "修改";
      this.shareSettingDialogParam.visible = true;
    },
  },
  filters: {},
  watch: {
    jsonArray: function () {
      this.shareSetting = this.jsonArray;
    }
  },
  mounted () {
    getShareAccount().then(response => {
      if (response.data.success) {
        this.allShareAccount = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
  },
}
</script>
