import { httpForm, httpJson, httpFile } from '@/utils/adminRequest'
//定义一个Downloads接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/downloads/list', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/downloads/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/downloads/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/downloads/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/downloads/delete', formData)
}