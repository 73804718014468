import { httpJson } from '@/utils/adminRequest'
/**
 * 得到经营范围
 */
export const getBizKind = (tableParam) => {
  return httpJson.get('/bizKind/findDataBy', { 'params': tableParam })
}

/**省-市-区 */
export const getAreaAll = (tableParam) => {
  return httpJson.get('/areaCity/findDataBy', { 'params': tableParam })
}

/**
 * 得到所有设备分类
 * @returns 
 */
export const getAllDevKind = () => {
  return httpJson.get('/biz/allDevKind')
}

/**
 * 得到所有系统状态
 * @returns 
 */
export const getSysStatus = () => {
  return httpJson.get('/biz/sysStatus')
}
/**
 * 得到所有的正常签约的小区
 * @returns 
 */
export const getAllPublicArea = () => {
  return httpJson.get('/biz/allPublicArea')
}
/**
 * 得到小区的场地类型
 */
export const getAreaTypeInfo = () => {
  return httpJson.get('/biz/areaTypeInfo')
}

/**
 * 得到所有的价格体系
 */
export const getAllPricesInfo = () => {
  return httpJson.get('/biz/allPrices')
}
/**
 * 得到分润的所有帐号
 */
export const getShareAccount = () => {
  return httpJson.get('/biz/shareAccount')
}
