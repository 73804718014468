<!--认证合同Echart-->
<template>
  <div :id="'orderPower_'+this.orderId" style="width: auto;width:100%,height: 300px"></div>
</template>
<script> 
import { getPowerChart } from '@/api/biz/ElecOrders.js';
import dayjs from 'dayjs';
export default {
  props: ["orderId"],
  data () {
    return {
      myCharts: null,
    };
  },
  methods: {
    updateEchart () {
      let param = { orderId: this.orderId }
      getPowerChart(param).then(response => {
        if (response.data.success) {
          this.$nextTick(function () {
            this.echartInitContract('orderPower_' + this.orderId, response.data.data);
          });
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      });
    },
    echartInitContract (idName, optionData) {
      console.log(optionData)
      let powerNum = new Array();
      let eleNum = new Array();
      let powerData = new Array();
      let energyData = new Array();
      let xPowerMax = 0;
      let xEleMax = 0;
      for (let i = 0; i < optionData.length; i++) {
        if (optionData[i].logType == 2) {
          eleNum.push(optionData[i].countValue);
          energyData.push({ time: optionData[i].coutTime, value: optionData[i].countValue });
        }
        else if (optionData[i].logType == 3) {
          powerNum.push(optionData[i].countValue);
          powerData.push({ time: optionData[i].coutTime, value: optionData[i].countValue });
        }
      }
      if (powerNum.length > 0) {
        // 向上取10，即找到最接近且大于 maxPower 的10的倍数
        xPowerMax = Math.ceil(Math.max(...powerNum) / 10) * 10;
      }
      if (eleNum.length > 0) {
        xEleMax = Math.ceil(Math.max(...eleNum) / 0.5);
      }
      this.myCharts.setOption({
        title: {
          text: '',
          subtext: ''
        },
        tooltip: {
          trigger: 'axis',
          axisPointer: {
            type: 'cross'
          },
          formatter: function (params) {
            var tooltipText = '';
            params.forEach(function (item) {
              if (item.seriesName === '功率') {
                tooltipText += '功率: ' + item.value[1] + ' W<br/>';
              } else if (item.seriesName === '用电量') {
                tooltipText += '用电量: ' + item.value[1] + ' kWh<br/>';
              }
            });
            console.log(1111111111);
            return new dayjs(new Date(params[0].value[0])).format('YYYY-MM-DD hh:mm') + '<br/>' + tooltipText;
          }
        },
        legend: {
          data: ['功率', '用电量']
        },
        toolbox: {
          show: true,
          feature: {
            dataZoom: {
              show: false,
              yAxisIndex: 'none'
            },
            dataView: {
              readOnly: true,
              optionToContent: function (opt) {
                var table = ['<table style="width:40%;margin-right:20px;float:left;text-align:center;border-collapse: collapse;border: 1px solid black;"><tbody><tr>'
                  + '<th>时间</th><th>功率(W)</th></tr>'];

                for (var i = 0, l = powerData.length; i < l; i++) {
                  // 将时间戳转换为本地时间字符串
                  var timeStr = powerData[i].time;
                  var powerValue = powerData[i].value;
                  table.push('<tr>'
                    + '<td>' + timeStr + '</td>'
                    + '<td>' + powerValue + 'W</td>'
                    + '</tr>');
                }
                table.push('</tbody></table>');
                var table2 = ['<table style="width:40%;float:left;text-align:center;border-collapse: collapse;border: 1px solid black;"><tbody><tr>'
                  + '<th>时间</th><th>累计用电量(度)</th></tr>'];
                for (var i = 0, l = energyData.length; i < l; i++) {
                  // 将时间戳转换为本地时间字符串
                  var timeStr = energyData[i].time;
                  var energyValue = energyData[i].value;;
                  table2.push('<tr>'
                    + '<td>' + timeStr + '</td>'
                    + '<td>' + energyValue + '度</td>'
                    + '</tr>');
                }
                table2.push('</tbody></table>');
                return table.join('') + table2.join('');
              },
              contentToOption: function (content) {
                // 如果是只读模式，不需要实现此方法
                return null;
              }


            },
            magicType: { show: false, type: ['line'] },
            restore: { show: false },
            saveAsImage: { show: true }
          }
        },
        xAxis: {
          type: 'time',
          boundaryGap: false
        },
        yAxis: [{
          max: xPowerMax,
          name: '功率(W)',
          type: 'value'
        }, {
          min: 0,
          max: xEleMax,
          name: '用电量(kWh)',
          type: 'value',
          inverse: false, // 是否反向
          position: 'right', // 设置 y 轴的位置
          offset: 0, // 从默认位置偏移
          splitLine: {
            show: false
          }
        }],
        series: [{
          name: '功率',
          type: 'line',
          data: powerData.map(function (item) {
            return [new Date(item.time).getTime(), item.value];
          }),
          yAxisIndex: 0 // 指定使用第一个 y 轴
        }, {
          name: '用电量',
          type: 'bar',
          data: energyData.map(function (item) {
            return [new Date(item.time).getTime(), item.value];
          }),
          yAxisIndex: 1 // 指定使用第二个 y 轴
        }]
      });
    },
  },
  watch: {
    orderId: function () {
      this.updateEchart();
    }
  },
  mounted () {
    Object.defineProperty(document.getElementById('orderPower_' + this.orderId), 'clientHeight', { get: function () { return 300 } })//el-tab 无法取得,所以改了这个BUG
    this.myCharts = this.$echarts.init(document.getElementById('orderPower_' + this.orderId));
    let param = { orderId: this.orderId }
    getPowerChart(param).then(response => {
      if (response.data.success) {
        this.$nextTick(function () {
          this.echartInitContract('orderPower_' + this.orderId, response.data.data);
        });
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    });
  },
}
</script>