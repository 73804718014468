import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个Kind接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/kind/list', { 'params': tableParam })
}
export const getAllPulbic = () => {
    return httpJson.get('/kind/allPublic')
}
export const getInfo = (param) => {
    return httpJson.get('/kind/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/kind/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/kind/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/kind/delete', formData)
}
export const doLock = (formData) => {
    return httpForm.post('/kind/lock', formData)
}

