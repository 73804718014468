import { httpForm, httpFile, httpJson } from '@/utils/adminRequest'
//定义一个Cer取得接口,数据表接口
/**
 * 修改用户密码
 * @param {} formData 
 * @returns 
 */
export const doModify = (formData) => {
    return httpForm.post('/setting/modify', formData)
}
export const getSetting = () => {
    return httpJson.get('/settingInfo')
}

export const getCer = () => {
    return httpJson.get('/cert/info')
}
export const getSys = () => {
    return httpJson.get('/sys/properties')
}
export const getDiskInfo = () => {
    return httpJson.get('/sys/diskInfo')
}
export const getDirInfo = () => {
    return httpJson.get('/sys/dirInfo')
}
export const postCerFile = (fileData) => {
    return httpFile.post('/upload/cerFile', fileData)
}



