import { httpForm, httpJson } from '@/utils/adminRequest'
//定义一个ElecShareAccount接口,数据表接口
export const getTableData = (tableParam) => {
    return httpJson.get('/elecShareAccount/list', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/elecShareAccount/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/elecShareAccount/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/elecShareAccount/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/elecShareAccount/delete', formData)
}
export const doPermit = (formData) => {
    return httpForm.post('/elecShareAccount/permit', formData)
}

