<template>
  <div id="loginLayout">
    <div class="login-form-wrap">
      <div class="login-title">{{settingData.siteTitle}}</div>
      <el-form :model="adminData" :rules="adminRules" ref="adminLoginForm" label-width="80px" class="loginCss">
        <h3>{{settingData.siteSub}}</h3>
        <el-form-item label="账 号" prop="loginName">
          <el-input v-model="adminData.loginName" prefix-icon="el-icon-user" placeholder="请输入账号"></el-input>
        </el-form-item>
        <el-form-item label="密 码" prop="loginPass">
          <el-input v-model="adminData.loginPass" prefix-icon="el-icon-lock" placeholder="请输入密码" type="password"
            @keyup.enter.native="submitForm('adminLoginForm')"></el-input>
        </el-form-item>
        <el-form-item label="验证码" prop="checkCode">
          <img />
          <el-input v-model="adminData.checkCode" placeholder="请输入验证码" @keyup.enter.native="submitForm('adminLoginForm')">
            <template slot="prepend"><img :src="'data:image/Jpeg;base64,'+this.adminData.validateImg" style="height:33px" @click="refreshAdminCode"></template>
          </el-input>
        </el-form-item>
        <el-form-item>
          <el-button type="primary" @click="submitForm('adminLoginForm')">登 录</el-button>
          <el-button @click="resetForm('adminLoginForm')">重 置</el-button>
        </el-form-item>
      </el-form>
      <div class="login-copy">
        Powered By Weber.Tech <a href="https://www.luoui.com" target="_blank">support</a><br>
        @2012 - 2023
      </div>
    </div>
  </div>
</template>
<style  lang="less"  scoped>
.loginSelect {
  width: 440px;
  height: 40px;
  margin: 0 auto;
  div {
    font-size: 20px;
    height: 40px;
    line-height: 40px;
    font-weight: bold;
    color: #fff;
    width: 130px;
    float: left;
    text-align: center;
    background-color: #666666;
    cursor: pointer;
    overflow: hidden;
  }
  display: block;
}
.noActive {
  font-size: 28px;
  color: #cccccc !important;
  background-color: #444444 !important;
  //border-bottom: 1px solid #666666;
}
.loginCss {
  width: 400px;
  padding: 20px;
  padding-top: 40px;
  margin: 0 auto;
  height: 280px;
  background-color: #666666;
  h3 {
    color: #fff;
    margin-bottom: 20px;
    text-align: center;
  }
}
* {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}
.login-form-wrap {
  position: relative;
  padding-top: 15vh;
  width: 700px;
  height: 85vh;
  margin: 0 auto;
  background: rgba(0, 0, 0, 0.5);
  border: 1px solid rgba(0, 0, 0, 0.6);
  border-top: 0;
  border-bottom: 0;
  box-shadow: 0 0 15px rgba(0, 0, 0, 0.35);
}
.login-form-wrap form {
  box-shadow: 0 0 20px rgba(0, 0, 0, 0.15);
}
.login-title,
.login-copy {
  font-weight: bold;
  font-family: "Microsoft Yahei";
  color: #fff;
  text-align: center;
  text-shadow: 0 3px 8px rgba(0, 0, 0, 0.2);
}
.login-title {
  font-size: 30px;
  margin-bottom: 5vh;
}
.login-copy {
  position: absolute;
  bottom: 3vh;
  width: 100%;
  font-weight: 200;
  font-size: 12px;
  text-transform: uppercase;
}
.login-copy a {
  text-decoration: none;
  color: #fff;
}
.login-copy a:hover {
  color: #ff0000;
}
</style>
<style scoped>
.loginCss >>> .el-form-item__label {
  color: #fff;
}
</style>
<script>
import { adminLogin, getAdminValidateCode, getSetting } from '@/api/login'; //数据源
//这里可以导入其他文件（比如：组件，工具js，第三方插件js，json文件，图片文件等等）
//例如：import 《组件名称》 from '《组件路径》';
export default {
  //import引入的组件需要注入到对象中才能使用
  components: {},
  data () {
    return {
      settingData: {},
      adminData: {

        loginName: process.env.VUE_APP_AUTO_LOGIN === 'true' ? 'superadmin' : '',  //开发和正式环境的密码帐号不同
        loginPass: process.env.VUE_APP_AUTO_LOGIN === 'true' ? '111111' : '',
        checkCode: '',
        validateImg: '', //验证图片
      },
      adminRules: {
        loginName: [{
          required: true,
          message: '请输入账号',
          trigger: 'change'
        }],
        loginPass: [{
          required: true,
          message: '请输入密码',
          trigger: 'blur'
        },
        {
          min: 6,
          max: 12,
          message: '长度在 6 到 12 个字符',
          trigger: 'blur'
        }
        ],
        checkCode: {
          required: true,
          message: '请输入验证码',
          trigger: 'change'
        }
      }
    };
  },
  //监听属性 类似于data概念
  computed: {},
  //监控data中的数据变化
  watch: {
    "$route": function () {
      this.userInfo = this.$store.getters.getLoginUser;
    }
  },
  //方法集合
  methods: {
    refreshAdminCode () {
      getAdminValidateCode().then(response => {
        if (response.data.success) {
          this.adminData.validateImg = response.data.data.validateImg;
          this.adminData.checkCode = response.data.data.validateCode;
          this.$store.commit("setAuthorizationToken", response.data.data.token);
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      })
    },
    initSetting () {
      getSetting().then(response => {
        if (response.data.success) {
          this.settingData = response.data.data;
        }
        else {
          this.notifyWarning(response.data.msg);
        }
      })
    },
    submitForm (formName) {
      this.$refs[formName].validate((valid) => {
        if (valid) {
          adminLogin(this.adminData).then(response => {
            if (response.data.success) {
              this.notifySuccess(response.data.msg);
              console.log(response.data.data);
              this.$store.commit("setToken", response.data.data.token);
              this.$store.commit("setLoginUser", response.data.data);
              //console.log(response.data.data)
              setTimeout(() => {
                this.$router.push("/" + this.$store.getters.getLoginUser().home);
              }, 1000);

            }
            else {
              this.notifyWarning(response.data.msg);
              this.refreshAdminCode();
            }
          })
        }
        else {
          return false;
        }
      });
    },
    resetForm (formName) {
      this.$refs[formName].resetFields();
    },
  },
  //生命周期 - 创建完成（可以访问当前this实例）
  created () { },
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    let _html_bg_src = parseInt(Math.random() * 10)
    document.querySelector('#loginLayout').style = `
    position: fixed;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    margin: 0;
    padding: 0;
    background: url(/bgImg/${_html_bg_src}.jpg);
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center center;
  `;
    this.refreshAdminCode();
    this.initSetting();
  },
  beforeCreate () { }, //生命周期 - 创建之前
  beforeMount () { }, //生命周期 - 挂载之前
  beforeUpdate () { }, //生命周期 - 更新之前
  updated () { }, //生命周期 - 更新之后
  beforeDestroy () { }, //生命周期 - 销毁之前
  destroyed () { }, //生命周期 - 销毁完成
  activated () { }, //如果页面有keep-alive缓存功能，这个函数会触发
}
</script>