<template>
  <span>
    <el-card class="box-card">
      <div slot="header" class="clearfix">
        <span><i class="el-icon-coin" style="color:#67C23A"></i> 系统空间</span>
        <router-link :to="{name: 'backUp'}"><el-button type="text" style="padding: 0 3px;float: right;">备份管理</el-button></router-link>
      </div>
      <div class="text item">
        <el-form label-width="80px" size="mini">
          <el-row style="padding:0px;">
            <el-col :span="12">
              <el-form-item label="盘符">{{dirInfo.diskDirName}}</el-form-item>
              <el-form-item label="类型">{{dirInfo.diskSysTypeName}}</el-form-item>
              <el-form-item label="已使用">{{(dirInfo.diskUsed/1024).toFixed(2)}}G</el-form-item>
              <el-form-item label="总大小">{{(dirInfo.diskTotal/1024).toFixed(2)}}G</el-form-item>
            </el-col>
            <el-col :span="12">
              <el-form-item label="可使用">{{(dirInfo.diskFree/1024).toFixed(2)}}G</el-form-item>
              <el-progress :stroke-width="18" :color="diskColors" type="circle" :percentage="countPercentage(dirInfo.diskUsed,dirInfo.diskTotal)"></el-progress>
            </el-col>
          </el-row>
        </el-form>
      </div>
    </el-card>
  </span>
</template>
 <script> 
import { getDirInfo } from '@/api/wbkj/Setting.js';
export default {
  data () {
    return {
      diskColors: [
        { color: '#6f7ad3', percentage: 20 },
        { color: '#1989fa', percentage: 40 },
        { color: '#5cb87a', percentage: 60 },
        { color: '#e6a23c', percentage: 80 },
        { color: '#f56c6c', percentage: 100 },
      ],
      dirInfo: {},
    }
  },
  mounted () {
    getDirInfo().then(response => {
      if (response.data.success) {
        this.dirInfo = response.data.data;
      }
      else {
        this.notifyWarning(response.data.msg);
      }
    })
  }
}
</script>