<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="19">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="5">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" style="width:100%" @change="tableChangeSearchKey">
            <el-button type="primary" slot="append"><i class="fa fa-search" style="color:#409EFF"></i></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-button-group>
          <el-button @click="formDataNew" type="primary">新建</el-button>
          <el-button @click="formDataModify" type="primary">修改</el-button>
          <el-button @click="formDataDelete" type="primary">删除</el-button>
        </el-button-group>
      </el-col>
    </el-row>

    <el-table ref="testCreateTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column header-align="center" type="index" width="40"></el-table-column>
      <el-table-column header-align="center" label="" prop="id" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" label="名称Varchar" prop="testName" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.testName)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="text类型" prop="testText" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.testText)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="text类型" prop="testArea" show-overflow-tooltip>
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.testArea)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="单图(字段名里带Img)" prop="testImg" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-image v-if="scope.row.testImg.length>10" :src="scope.row.testImg" :preview-src-list="[scope.row.testImg]"></el-image>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="单链接(字段名里带Url)" prop="testUrl" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-tooltip class="item" effect="light" content="点击下载源文件" placement="top-start">
            <i class="el-icon-connection" style="font-size:16px;color: #409EFF;font-weight: 400;" @click="downloadFile(scope.row.testUrl,'')"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="单文件(字段名里带File)" prop="testFile" show-overflow-tooltip>
        <template slot-scope="scope">
          <el-tooltip class="item" effect="light" content="点击下载源文件" placement="top-start">
            <i class="el-icon-connection" style="font-size:16px;color: #409EFF;font-weight: 400;" @click="downloadFile(scope.row.testFile,'')"></i>
          </el-tooltip>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="JSON类型(字段名里带Img)为多图片上传" width="200" prop="testMoreImg">
        <template slot-scope="scope">
          <file-view :fileList="scope.row.testMoreImg"> </file-view>
        </template>
      </el-table-column>

      <el-table-column header-align="center" label="JSON类型(字段名里带File)为多文件上传" width="200" prop="testMoreFile">
        <template slot-scope="scope">
          <file-view :fileList="scope.row.testMoreFile"> </file-view>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="JSON类型(注释里带Info)为多(key-Value)" prop="testJsonExt" show-overflow-tooltip>
        <template slot-scope="scope">
          <span style="padding-left: 5px;" v-for="(val,key,i) in scope.row.testJsonExt" :key="i">
            <el-button slot="reference" size="small" type="success" plain>{{key}}:{{val}}</el-button>
          </span>
        </template>
      </el-table-column>

      <el-table-column header-align="center" label="JSON类型(注释里带array)为数据多条" prop="testJsonList">
        <template slot-scope="scope">
          <span :key="index" v-for="(item,index) in scope.row.testJsonList" style="margin-right:5px;">
            <el-tooltip placement="top">
              <div slot="content" style="line-height:20px">姓名:{{item.name}}<br />类型:{{item.type}}<br />手机:{{item.mobile}}<br /></div>
              <el-tag>{{item.name}}</el-tag>
            </el-tooltip>
          </span>
        </template>
      </el-table-column>

      <el-table-column header-align="center" label="数字整型INT" prop="testInt" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" label="科学精度DECIMAL" prop="testDecimail" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" label="布尔类型TINYINT(1)" prop="testTinyint">
        <template slot-scope="scope">
          <span v-if="scope.row.testTinyint">
            是
          </span>
          <span v-else>
            否
          </span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="布尔类型Bit(1)" prop="testBit">
        <template slot-scope="scope">
          <span v-if="scope.row.testBit">
            是
          </span>
          <span v-else>
            否
          </span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="日期类型DATE" prop="testDate" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" label="时间类型DATETIME" prop="testDatetime" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" label="treeTable必须有的parentId" prop="parentId" show-overflow-tooltip></el-table-column>
      <el-table-column header-align="center" label="treeTable必须有的是否结点" prop="isNode">
        <template slot-scope="scope">
          <span v-if="scope.row.isNode">
            是
          </span>
          <span v-else>
            否
          </span>
        </template>
      </el-table-column>

    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>

    <!--编辑的表单-->

    <el-dialog v-dialogDrag :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width" :top="formDialogParam.top"
      :close-on-click-modal='false'>
      <el-form ref="testCreateFormRef" :model="formData" :rules="formDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-form-item label="名称Varchar" prop="testName">
          <el-input id="testName" v-model="formData.testName" placeholder="请输入名称Varchar"></el-input>
        </el-form-item>

        <el-form-item label="text类型" prop="testText">
          <el-input id="testText" v-model="formData.testText" placeholder="请输入text类型"></el-input>
        </el-form-item>

        <el-form-item label="text类型" prop="testArea">
          <el-input id="testArea" v-model="formData.testArea" type="textarea" :rows="3" resize="none" placeholder="请输入text类型"></el-input>
        </el-form-item>

        <el-form-item label="单图(字段名里带Img)" prop="testImg"> <!-- `prop` 为绑定的规则 -->
          <upload-img id="testImg" :inputValue="formData.testImg" label="单图(字段名里带Img)" allow=".jpg.gif.png.jpeg" childDir="testCreate" :readOnly="true"
            @success="testImgSuccessUpload"></upload-img>
        </el-form-item>

        <el-form-item label="单链接(字段名里带Url)" prop="testUrl"> <!-- `prop` 为绑定的规则 -->
          <upload-file id="testUrl" :inputValue="formData.testUrl" label="单链接(字段名里带Url)" allow=".doc.docx.xls.xlsx.ppt.pptx.pdf.zip.rar.wps.jpg.gif.png.jpeg"
            childDir="testCreate" :readOnly="false" @success="testUrlSuccessUpload"></upload-file>
        </el-form-item>

        <el-form-item label="单文件(字段名里带File)" prop="testFile"> <!-- `prop` 为绑定的规则 -->
          <upload-file id="testFile" :inputValue="formData.testFile" label="单文件(字段名里带File)" allow=".doc.docx.xls.xlsx.ppt.pptx.pdf.zip.rar.wps"
            childDir="testCreate" :readOnly="true" @success="testFileSuccessUpload"></upload-file>
        </el-form-item>

        <el-form-item label="JSON类型(字段名里带Img)为多图片上传" prop="testMoreImg">
          <upload-json-img id="testMoreImg" :inputArray="formData.testMoreImg" label="JSON类型(字段名里带Img)为多图片上传" childDir="testCreate"
            @success="testMoreImgSuccessUpload"></upload-json-img>
        </el-form-item>

        <el-form-item label="JSON类型(字段名里带File)为多文件上传" prop="testMoreFile">
          <upload-json-file id="testMoreFile" :inputArray="formData.testMoreFile" label="JSON类型(字段名里带File)为多文件上传" childDir="testCreate"
            @success="testMoreFileSuccessUpload"></upload-json-file>
        </el-form-item>

        <el-form-item label="JSON类型(注释里带Info)为多(key-Value)" prop="testJsonExt">
          <info-test-json-ext id="testJsonExt" :jsonInfo="formData.testJsonExt" @success="testJsonExtSuccess"></info-test-json-ext>
        </el-form-item>

        <el-form-item label="JSON类型(注释里带array)为数据多条" prop="testJsonList">
          <tag-test-json-list id="testJsonList" :jsonArray="formData.testJsonList" @success="testJsonListSuccess"></tag-test-json-list>
        </el-form-item>
        <el-form-item label="JSON类型(注释里带array)为数据多条" prop="testJsonList">
          <table-test-json-list id="testJsonList" :jsonArray="formData.testJsonList" @success="testJsonListSuccess"></table-test-json-list>
        </el-form-item>

        <el-form-item label="数字整型INT" prop="testInt">
          <el-input-number id="testInt" v-model="formData.testInt" controls-position="right" :min="0" :max="2147483647"
            placeholder="请输入数字整型INT"></el-input-number>
        </el-form-item>

        <el-form-item label="科学精度DECIMAL" prop="testDecimail">
          <el-input-number id="testDecimail" v-model="formData.testDecimail" controls-position="right" :precision="2" :step="0.1" :min="0" :max="2147483647.00"
            placeholder="请输入科学精度DECIMAL"></el-input-number>
        </el-form-item>

        <el-form-item label="布尔类型TINYINT(1)">
          <el-checkbox v-model="formData.testTinyint">布尔类型TINYINT(1)</el-checkbox>
        </el-form-item>

        <el-form-item label="布尔类型Bit(1)">
          <el-checkbox v-model="formData.testBit">布尔类型Bit(1)</el-checkbox>
        </el-form-item>

        <el-form-item label="日期类型DATE" prop="testDate">
          <el-date-picker id="testDate" v-model="formData.testDate" value-format="yyyy-MM-dd" format="yyyy-MM-dd" type="date"
            placeholder="请选择日期类型DATE"></el-date-picker>
        </el-form-item>

        <el-form-item label="时间类型DATETIME" prop="testDatetime">
          <el-date-picker id="testDatetime" v-model="formData.testDatetime" type="datetime" format="yyyy-MM-dd HH:mm" value-format="yyyy-MM-dd HH:mm"
            placeholder="请选择时间类型DATETIME"></el-date-picker>
        </el-form-item>

        <el-form-item label="treeTable必须有的parentId" prop="parentId">
          <el-input-number id="parentId" v-model="formData.parentId" controls-position="right" :min="0" :max="2147483647"
            placeholder="请输入treeTable必须有的parentId"></el-input-number>
        </el-form-item>

        <el-form-item label="treeTable必须有的是否结点">
          <el-checkbox v-model="formData.isNode">treeTable必须有的是否结点</el-checkbox>
        </el-form-item>

      </el-form>
      <span slot="footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="50%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column prop="title" label="选定的记录">
        </el-table-column>
      </el-table>
      <span slot="footer">
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>
import UploadJsonImg from '@/components/utils/form/UploadJsonImg.vue';
import UploadFile from '@/components/utils/form/UploadFile.vue';
import FileView from '@/components/utils/FileView.vue';
import UploadJsonFile from '@/components/utils/form/UploadJsonFile.vue';
import TableTestJsonList from '@/components/testCreate/TableTestJsonList.vue';
import InfoTestJsonExt from '@/components/testCreate/InfoTestJsonExt.vue';
import TagTestJsonList from '@/components/testCreate/TagTestJsonList.vue';
import UploadImg from '@/components/utils/form/UploadImg.vue';
import store from '@/store';
import { getTableData, doNew, doModify, doDelete, } from '@/api/wbkj/TestCreate'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响
import { required, phone, len, email } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  components: { UploadJsonImg, TableTestJsonList, FileView, UploadFile, InfoTestJsonExt, TagTestJsonList, UploadJsonFile, UploadImg },
  data () {
    return {
      /*导航栏状态 */
      direcParam: {
        'directName': '名称名称名称名称名称名称名称名称名称TestCreate'
      },
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'tableKey': 0,
        'searchType': 'normal'
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '50%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: ''
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        testName: '',
        testText: '',
        testArea: '',
        testImg: '',
        testUrl: '',
        testFile: '',
        testMoreImg: [],
        testMoreFile: [],
        testJsonExt: {},
        testJsonList: [],
        testInt: 0,
        testDecimail: 0.00,
        testTinyint: false,
        testBit: false,
        testDate: new Date(),
        testDatetime: '',
        parentId: 0,
        isNode: false,

      },
      formDialogRules:
      {
        testName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        testText: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        testArea: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        testImg: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        testUrl: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        testFile: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        testMoreImg: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        testMoreFile: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        testJsonExt: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        testJsonList: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        testInt: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        testDecimail: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        testTinyint: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        testBit: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        testDate: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        testDatetime: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        parentId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isNode: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增

        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
    }
  },
  methods: {

    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定的记录(已发布的不删除)"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    formDataValidate () {
      let result = true;
      this.$refs.testCreateFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.formData));
      postData.testMoreImg = JSON.stringify(this.formData.testMoreImg);//由于MysqlJson的原因
      postData.testMoreFile = JSON.stringify(this.formData.testMoreFile);//由于MysqlJson的原因
      postData.testJsonExt = JSON.stringify(this.formData.testJsonExt);//由于MysqlJson的原因
      postData.testJsonList = JSON.stringify(this.formData.testJsonList);//由于MysqlJson的原因

      //postData.classId = this.tableParam.classId;
      //postData.imageUrl = JSON.stringify(this.formData.imageUrl);//由于MysqlJson的原因
      //postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因
      //doNew(this.formData).then(response => {
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    formChooseSubmit () {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      console.log(row)
      this.$refs.testCreateTableRef.clearSelection(); //清除选中
      this.$refs.testCreateTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },
    testImgSuccessUpload (data) {  //上传testImg成功,更新数据
      this.formData.testImg = data.serverPath;
    },

    testUrlSuccessUpload (data) {  //上传testUrl成功,更新数据
      this.formData.testUrl = data.serverPath;
    },

    testFileSuccessUpload (data) {  //上传testFile成功,更新数据
      this.formData.testFile = data.serverPath;
    },

    testMoreImgSuccessUpload (data) { //上传testMoreImg成功,更新数据
      this.formData.testMoreImg = data;
    },


    testMoreFileSuccessUpload (data) { //上传testMoreFile成功,更新数据
      this.formData.testMoreFile = data;
    },

    testJsonExtSuccess (data) { //JsonInfo,数据回传testJsonExt成功,更新数据
      this.formData.testJsonExt = data;
    },

    testJsonListSuccess (data) { //JsonArray,数据回传testJsonList成功,更新数据
      this.formData.testJsonList = data;
    },


  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  filters: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.direcParam = JSON.parse(store.getters.getActiveCus());
    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    this.tableDateRefresh();
  }
}
</script>
