<!--此组件是为了无限生成菜单模块-->
<template>
  <!-- // -->
  <div :style="'float:'+float+';'">
    <el-submenu v-if="data.isNode" :index="'left'+data.id" :style="data.bodyItems.length>0?'display:block':'display:none'">
      <template slot="title" v-if="viewSub">
        <i :class="data.iconHref"></i>
        <span slot="title">{{data.viewName}}</span>
      </template>
      <template slot="title" v-else>
        <i :class="data.iconHref"></i>&nbsp;&nbsp;
      </template>
      <menu-panel v-for="item in data.bodyItems" :data="item" :item-height="itemHeight" :key="'top_'+item.routeIndex+item.id"></menu-panel>
    </el-submenu>
    <el-menu-item v-else :index="data.routeIndex+data.id" @click="clickMenuItem(data)" :style="'height:'+itemHeight+';line-height:'+itemHeight">
      <i :class="data.iconHref"></i>{{data.viewName}}</el-menu-item>
  </div>
</template>
<script> 
export default {
  name: 'MenuPanel',
  props: ["data", "float", "itemHeight", "viewSub",],
  data () { return {} },
  methods: {
    clickMenuItem (bodyItem) {
      if (bodyItem.linkType == 1) {
        let tempRoutePath = "/" + bodyItem.routeIndex;
        let extRouteName = bodyItem.extParam.routeName;//特殊的路由方式
        this.$store.commit('setActiveCus', { id: bodyItem.linkCusPanelId, directName: bodyItem.viewName, routeIndex: bodyItem.routeIndex, routePath: tempRoutePath, extRouteName: extRouteName }); //存到Store里,store已经存在cookie里了,万一刷新页面不丢
        if (!(this.$route.name == bodyItem.routeIndex)) {
          this.$router.push({
            name: bodyItem.routeIndex,
          });
        }
      }
      else if (bodyItem.linkType == 2) {
        let tempRoutePath = "/" + bodyItem.routeIndex + "/" + bodyItem.linkDocPanelId;
        this.$store.commit('setActiveCus', { id: bodyItem.linkDocPanelId, directName: bodyItem.viewName, routeIndex: bodyItem.routeIndex, routePath: tempRoutePath }); //存到Store里,store已经存在cookie里了,万一刷新页面不丢
        if (!(this.$route.path == tempRoutePath)) {
          this.$router.push({
            name: bodyItem.routeIndex, params: { id: bodyItem.linkDocPanelId }
          });
        }
      }
    },
  },
}
</script>