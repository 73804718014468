<template>
  <div>
    <el-row :gutter="20">
      <el-col :span="19">
        <el-breadcrumb separator-class="el-icon-arrow-right">
          <el-breadcrumb-item :to="{ path: '/' }">首页</el-breadcrumb-item>
          <el-breadcrumb-item>{{ this.direcParam.directName }}</el-breadcrumb-item>
        </el-breadcrumb>
      </el-col>
      <el-col :span="5">
        <div>
          <el-input placeholder="请输入搜索内容" v-model.trim="tableParam.key" style="width:100%" @change="tableChangeSearchKey">
            <el-button type="primary" slot="append"><i class="fa fa-search" style="color:#409EFF"></i></el-button>
          </el-input>
        </div>
      </el-col>
    </el-row>
    <el-row>
      <el-col :span="24">
        <el-button-group>
          <!-- <el-button @click="formDataNew" type="primary">新建</el-button>
          <el-button @click="formDataModify" type="primary">修改</el-button> -->
          <el-button @click="formDataClose" type="primary">关闭</el-button>
          <el-button @click="formDataDelete" type="primary">删除</el-button>
          <el-button @click="formDataRefunds" type="danger">退款</el-button>
        </el-button-group>
      </el-col>
    </el-row>

    <el-table ref="elecOrdersTableRef" :key="tableParam.tableKey" :border="true" height="666" :cell-style="{height:'44px',padding:0,margin:0}" :data="tableData"
      tooltip-effect="dark" style="width: 100%" :highlight-current-row="true" @selection-change="tableSelectionChange" @row-click="tableRowClick">
      <el-table-column header-align="center" type="selection" width="40"></el-table-column>
      <el-table-column type="expand">
        <template slot-scope="props">
          <el-descriptions labelStyle="width:90px;text-align:right" :column="4" style="margin-left:10px;margin-right:10px;" border size="small">
            <el-descriptions-item label="用户ID">{{ props.row.userId }}</el-descriptions-item>
            <el-descriptions-item label="用户手机">{{ props.row.userMobile}}</el-descriptions-item>
            <el-descriptions-item label="微信用户ID">{{props.row.wxOpenId}}</el-descriptions-item>
            <el-descriptions-item label="设备信息">{{props.row.devKind}}-{{props.row.devNo}}-{{props.row.place}}插</el-descriptions-item>
            <el-descriptions-item label="微信业务号">{{ props.row.transactionId }}</el-descriptions-item>
            <el-descriptions-item label="最后更新">{{props.row.updateTime}}</el-descriptions-item>
            <el-descriptions-item label="充电开始">{{props.row.doStartTime}}</el-descriptions-item>
            <el-descriptions-item label="充电结束">{{props.row.doEndTime}}</el-descriptions-item>
            <el-descriptions-item :span="4" label="交易实据">
              <el-row :gutter="10" style="padding:3px">
                <el-col :span="2">
                  <el-tag size="small"
                    style="width:100%">电价:{{props.row.orderData.elePrice}}{{props.row.orderData.priceUnit}}/{{props.row.orderData.estEleUnit}}</el-tag>
                </el-col>
                <el-col :span="2">
                  <el-tag size="small"
                    style="width:100%">服务费:{{props.row.orderData.servicePrice}}{{props.row.orderData.priceUnit}}/{{props.row.orderData.estEleUnit}}</el-tag>
                </el-col>
                <el-col :span="2">
                  <el-tag size="small" style="width:100%">购买电量:{{props.row.orderData.estNum}}{{props.row.orderData.estEleUnit}}</el-tag>
                </el-col>
                <el-col :span="2">
                  <el-tag size="small" style="width:100%">充电:{{props.row.orderData.useSecondExp}}</el-tag>
                </el-col>
                <el-col :span="5">
                  <el-tag size="small" style="width:100%" v-if="props.row.orderData.payPath=='HF'">汇付交易单号:{{props.row.orderData.hf_req_id}}</el-tag>
                  <el-tag size="small" style="width:100%" v-if="props.row.orderData.payPath=='WX'">微信交易单号:{{props.row.orderData.wx_ord_id}}</el-tag>
                </el-col>
                <el-col :span="11">
                  <el-tag size="small" type="info">{{props.row.orderData.sif_expTotal}}</el-tag>
                </el-col>
              </el-row>
              <el-row :gutter="10" style="padding:3px">
                <el-col :span="2">
                  <el-tag size="small" type="success" style="width:100%">结算电费:{{props.row.orderData.sif_eleTotal}}分</el-tag>
                </el-col>
                <el-col :span="2">

                  <el-tag size="small" v-if="props.row.orderData.useHour" type="success"
                    style="width:100%">结算服务费:{{props.row.orderData.sif_hourService}}分</el-tag>
                  <el-tag size="small" v-else type="success" style="width:100%">结算服务费:{{props.row.orderData.sif_serviceTotal}}分</el-tag>
                </el-col>
                <el-col :span="2">
                  <el-tag size="small" type="success" style="width:100%">实际使用:{{props.row.orderData.factNum}}{{props.row.orderData.estEleUnit}}</el-tag>
                </el-col>
                <el-col :span="2">
                  <el-tag size="small" type="success" style="width:100%">结算时长:{{props.row.orderData.sif_useMinute}}分钟</el-tag>
                </el-col>
                <el-col :span="16" v-if="props.row.orderData.useHour">
                  <el-tag size="small" type="warning">结算方式:电量+时长</el-tag>
                  <el-tag size="small" type="warning" style="margin-left:5px;">封顶:{{props.row.orderData.maxHour}}小时</el-tag>
                  <el-tag size="small" type="warning" style="margin-left:5px;">时价:{{props.row.orderData.hourPrice}}{{props.row.orderData.priceUnit}}/小时</el-tag>
                </el-col>
                <el-col :span="16" v-else>
                  <el-tag size="small" type="warning">结算方式:电量</el-tag>
                </el-col>
              </el-row>
            </el-descriptions-item>
            <el-descriptions-item :span="3" label="订单描述">{{props.row.description}}-{{props.row.devNo}}-{{props.row.place}}插
            </el-descriptions-item>
            <el-descriptions-item :span="1" label="当前状态">{{props.row.statusTitle}}
            </el-descriptions-item>
            <el-descriptions-item :span="4" label="备注">
              <div v-html="formatTooltipContent(props.row.note)"></div>
            </el-descriptions-item>

            <!--
            <el-descriptions-item :span="4" label="网络信息">
              <el-descriptions :column="3" size="mini">
                <el-descriptions-item v-for="(item,index) in props.row.netInfo" :key="'netInfo_'+item.id"
                  :label="item.cnName">{{item.value}}</el-descriptions-item>
              </el-descriptions>
            </el-descriptions-item>

            <el-descriptions-item :span="4" label="参数信息">
              <el-descriptions :column="3" size="mini">
                <el-descriptions-item v-for="(item,index) in props.row.paramInfo" :key="'paramInfo_'+item.id"
                  :label="item.title">{{item.cnName}}-{{item.value}}</el-descriptions-item>
              </el-descriptions>
            </el-descriptions-item> -->

          </el-descriptions>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="订单ID" prop="id" width="100">

      </el-table-column>
      <el-table-column header-align="center" label="订单号" prop="orderCode">
        <template slot-scope="scope">
          <span style="font-family:monospace;font-size:14px;font-weight: bold;">{{scope.row.orderCode}}</span>
          <span><el-button title="查看订单功率图" size="mini" type="primary" style="float:right;padding:5px"
              @click="showPowerEchart(scope.row.id,scope.row.orderCode)"><i class="el-icon-s-data"></i></el-button></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="用户手机" prop="userMobile" width="110">
        <template slot-scope="scope">
          <span v-html="tableLightByKey(scope.row.userMobile)"></span>
        </template>
      </el-table-column>
      <el-table-column header-align="center" label="渠道" prop="payType" width="70" align="center">
      </el-table-column>
      <el-table-column header-align="center" label="支付(分)" prop="totalFee" width="90" align="right">
      </el-table-column>
      <el-table-column header-align="center" label="手续费(分)" prop="serviceFee" width="100" align="right"></el-table-column>
      <el-table-column header-align="center" label="已退款(分)" prop="refund" width="100" align="right"></el-table-column>
      <el-table-column header-align="center" label="退手续费(分)" prop="serviceRef" width="120" align="right"></el-table-column>
      <el-table-column header-align="center" label="结算金额(分)" prop="factFee" width="120" align="right"></el-table-column>
      <el-table-column header-align="center" label="当前订单状态" width="120">
        <template slot-scope="scope">
          <el-tooltip placement="top">
            <div slot="content" style="line-height:20px;">
              支付时间:{{scope.row.payTime}}<br />
            </div>
            <el-tag v-if="scope.row.payStatus==0" type='danger'>待支付</el-tag>
            <el-tag v-else-if="scope.row.payStatus==1">支付成功</el-tag>
            <el-tag v-else-if="scope.row.payStatus==2">支付失败</el-tag>
            <el-tag v-else-if="scope.row.payStatus==3">退款成功</el-tag>
            <el-tag v-else-if="scope.row.payStatus==4">正在退款</el-tag>
            <el-tag v-else-if="scope.row.payStatus==5">未知</el-tag>
          </el-tooltip>
        </template>
      </el-table-column>

      <el-table-column header-align="center" label="创建订单" prop="createTime" width="160"></el-table-column>
      <el-table-column header-align="center" label="状态" prop="doFinish" width="90">
        <template slot-scope="scope">
          <span v-if="scope.row.isClose">
            <el-tooltip class="item" effect="dark" :content="scope.row.statusTitle" placement="top-start">
              <el-tag type="danger"><i class="el-icon-error"></i>关闭</el-tag>
            </el-tooltip>
          </span>
          <span v-else-if="scope.row.doFinish">
            <el-tooltip class="item" effect="dark" :content="scope.row.statusTitle" placement="top-start">
              <el-tag type="success"><i class="el-icon-check"></i>完成</el-tag>
            </el-tooltip>
          </span>
          <span v-else>
            <el-tooltip class="item" effect="dark" :content="scope.row.statusTitle" placement="top-start">
              <el-tag type="warning"><i class="el-icon-loading"></i>进行</el-tag>
            </el-tooltip>
          </span>
        </template>
      </el-table-column>
    </el-table>
    <el-pagination @size-change="tableSizeChange" @current-change="tableCurrentChange" :current-page="tableParam.page"
      :page-sizes="[30,40, 50, 60,70,80,90,100]" :page-size="tableParam.limit" layout="total, sizes, prev, pager, next, jumper" :total="tableParam.count">
    </el-pagination>

    <!--编辑的表单-->

    <el-dialog v-dialogDrag :title="formDialogParam.title" :visible.sync="formDialogParam.visible" :width="formDialogParam.width" :top="formDialogParam.top"
      :close-on-click-modal='false'>

      <el-descriptions labelStyle="width:90px;text-align:right" :column="4" title="订单详情" style="margin-left:10px;margin-right:10px;" border size="small">
        <el-descriptions-item label="用户ID">{{ formData.userId }}</el-descriptions-item>
        <el-descriptions-item label="用户手机">{{ formData.userMobile}}</el-descriptions-item>
        <el-descriptions-item label="微信用户ID">{{formData.wxOpenId}}</el-descriptions-item>
        <el-descriptions-item label="设备信息">{{formData.devKind}}-{{formData.devNo}}-{{formData.place}}插</el-descriptions-item>
        <el-descriptions-item label="微信业务号">{{ formData.transactionId }}</el-descriptions-item>
        <el-descriptions-item label="最后更新">{{formData.updateTime}}</el-descriptions-item>
        <el-descriptions-item label="充电开始">{{formData.doStartTime}}</el-descriptions-item>
        <el-descriptions-item label="充电结束">{{formData.doEndTime}}</el-descriptions-item>
        <el-descriptions-item :span="4" label="订单描述">{{formData.description}}
        </el-descriptions-item>
        <el-descriptions-item :span="1" label="订单金额(分)">{{formData.totalFee}}
        </el-descriptions-item>
        <el-descriptions-item :span="1" label="结算金额(分)">{{formData.factFee}}
        </el-descriptions-item>
        <el-descriptions-item :span="2" label="订单状态">{{formData.statusTitle}}
        </el-descriptions-item>
        <el-descriptions-item :span="4" label="备注">
          <div v-html="formatTooltipContent(formData.note)"></div>
        </el-descriptions-item>
      </el-descriptions>
      <br>
      <el-form ref="elecOrdersFormRef" :model="formData" :rules="formDialogRules" label-width="80px" size="small"><!-- `prop` 为绑定的规则 -->
        <el-form-item label="退款金额" prop="refund">
          <el-row style="padding:0px;" :gutter="0">
            <el-col :span="4"><el-input-number id="totalFee" v-model="formData.refund" :max="formData.canReFund" style="width:100%" controls-position="right"
                :min="0" placeholder="单位(分)"></el-input-number></el-col>
            <el-col :span="12"><span style="color:#ef0000">&nbsp;&nbsp;&nbsp;&nbsp;*请注意</span>:订单产生手工退款记录,该订单会立即完成,并结束.</el-col>
          </el-row>
        </el-form-item>
        <el-form-item label="退款原因" prop="reason">
          <el-input id="note" v-model="formData.reason" type="textarea" :rows="3" resize="none" placeholder="请输入退款原因"></el-input>
        </el-form-item>

      </el-form>
      <span slot="footer">
        <el-button @click="formDialogParam.visible = false">取 消</el-button>
        <el-button type="primary" @click="formDataSubmit">确 定</el-button>
      </span>
    </el-dialog>

    <el-dialog v-dialogDrag :title="formChooseDialogParam.title" :visible.sync="formChooseDialogParam.visible" width="50%" :close-on-click-modal='false'>
      <el-table :data="tableMultipleSelection" style="width: 100%;" height="300">
        <el-table-column header-align="center" type="index" width="40"></el-table-column>
        <el-table-column prop="orderCode" label="订单编号">
        </el-table-column>
        <el-table-column prop="userMobile" label="用户手机">
        </el-table-column>

      </el-table>
      <span slot="footer">
        <el-button @click="formChooseDialogParam.visible = false">取 消</el-button>
        <el-button v-if="formChooseDialogParam.action1" type="danger" @click="formChooseSubmit(false)">{{formChooseDialogParam.action1View}}</el-button>
        <el-button v-if="formChooseDialogParam.action2" type="primary" @click="formChooseSubmit(true)">{{formChooseDialogParam.action2View}}</el-button>
      </span>
    </el-dialog>
    <el-dialog v-dialogDrag top="3vh" :title="powerEchartParam.title" :visible.sync="powerEchartParam.visible" width="120vh" :close-on-click-modal='false'>
      <power-echart :orderId="powerEchartParam.orderId"></power-echart> <!-- 功率图 -->
    </el-dialog>

  </div>
</template>
<style lang="less" scoped>
@import "@/assets/css/TableCus.css";
</style>
<script>

import store from '@/store';
import PowerEchart from '@/components/elecOrder/PowerEchart.vue';
import { getTableData, doNew, doModify, doDelete, doClose, doRefunds } from '@/api/biz/ElecOrders'; //数据源,如果没有上传文件的部分,请删除postFile, postImg,不删除也不影响

import { required, phone, len, email } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  components: { PowerEchart },
  data () {
    return {
      powerEchartParam: {
        orderId: 0,
        title: '',
        visible: false
      },
      /*导航栏状态 */
      direcParam: {
        'directName': '名称名称名称名称名称名称名称名称名称ElecOrders'
      },
      /*表格参数 */
      tableParam: {
        'page': 1,
        'limit': 40,
        'count': 0,
        'key': '',
        'tableKey': 0,
        'searchType': 'normal'
      },//表格的传参
      /*表格数据 */
      tableData: [],
      /*表格选中的数据 */
      tableMultipleSelection: [], //多选的数据
      /*表单的参数 */
      formDialogParam: //form弹出窗口的显示状态
      {
        visible: false,
        title: '编辑',
        formDataAction: '',
        width: '60%',
        top: '5vh',
      },
      formChooseDialogParam: //form发布弹出窗口的显示状态
      {
        formChooseAction: '',
        title: '',
        visible: false,
        action1: false,
        action1View: '',
        action2: false,
        action2View: ''
      },
      /*原始数据 */
      formRawData: {}, //由于不能直接清空所有的数据回到初使状态(表格参数据库和搜索数据不能清空),所以建立,在mount的时候作一个深拷贝,将formData的数据初使化的原始数据复制一份过来
      /*初使化数据 */
      formData: {
        id: 0,
        userId: 0,
        userMobile: '',
        wxOpenId: '',
        orderCode: '',
        tradeName: '',
        devKind: 0,
        devNo: '',
        place: 0,
        description: '',
        transactionId: '',
        totalFee: 0,
        refund: 0,
        canReFund: 0,
        factFee: 0,
        payNonce: '',
        payTime: '',
        payDate: new Date(),
        payStatus: 0,
        statusTitle: '',
        isDel: false,
        createTime: '',
        updateTime: '',
        doStartTime: '',
        doEndTime: '',
        doFinish: false,
        reason: '',
        note: '',

      },
      formDialogRules:
      {
        userId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        userMobile: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        wxOpenId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        orderCode: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        tradeName: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        devKind: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        devNo: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        place: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        description: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        transactionId: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        totalFee: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        factFee: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        payNonce: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        payTime: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        payDate: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        payStatus: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        statusTitle: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        isDel: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        createTime: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        updateTime: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        doStartTime: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        doEndTime: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        doFinish: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        note: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        refund: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        canReFund: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        reason: [required],//如果非必填,请去掉,如有其它验证,自行在formRules参考或新增
        // title: [required,len(0,255)],多规则绑定
        // mail: [required,len(0,255), email],多规则绑定
        // authorWriter: [required],
      },
    }
  },
  methods: {

    showPowerEchart (orderId, orderCode) {
      this.powerEchartParam.title = "订单ID:" + orderId + ",订单号:" + orderCode + ",功率电量图";
      this.powerEchartParam.orderId = orderId;
      this.powerEchartParam.visible = true;
    },
    /*-------------------------------------------------表单(form)事件 -----------------------------------------------*/
    /*表单(form)-新建 */
    formDataNew () {
      this.formData = JSON.parse(JSON.stringify(this.formRawData)); //深拷贝一份原始数据到formData,就相当于重置表单了
      this.formDialogParam.title = '新建';
      this.formDialogParam.formDataAction = 'new';
      this.formDialogParam.visible = true;
    },
    /*表单(form)-修改 */
    formDataModify () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能修改");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        this.formDialogParam.formDataAction = 'modify';
        this.formDialogParam.title = '修改';
        this.formDialogParam.visible = true;
      }

    },
    formDataRefunds () {
      if (this.tableMultipleSelection.length != 1) {
        this.notifyInfo("您只能选择一条记录,才能退款");
      }
      else {
        this.formData = JSON.parse(JSON.stringify(this.tableMultipleSelection[0])); //深COPY主要是为了不想改了,就渲染到table上造成不必要的麻烦;
        this.formData.canReFund = this.formData.totalFee - this.formData.refund;
        if (this.formData.canReFund <= 0) {
          this.notifyInfo("该订单可退款金额为0,不能退款");
        }
        else {
          this.formDialogParam.formDataAction = 'refunds';
          this.formDialogParam.title = '退款';
          this.formDialogParam.visible = true;

        }

      }

    },
    /*表单(form)-删除 */
    formDataDelete () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能删除");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要删除选定的记录(已发布的不删除)"
        this.formChooseDialogParam.formChooseAction = 'delete';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '删 除';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },
    /*表单(form)-关闭订单 */
    formDataClose () {
      if (this.tableMultipleSelection.length < 1) {
        this.notifyInfo("您必须最少选择一条记录,才能关闭");
        return;
      }
      else {
        this.formChooseDialogParam.title = "是否真的要关闭选定的订单记录"
        this.formChooseDialogParam.formChooseAction = 'close';
        this.formChooseDialogParam.action1 = true;
        this.formChooseDialogParam.action1View = '关 闭';
        this.formChooseDialogParam.action2 = false;
        this.formChooseDialogParam.action2View = '';
        this.formChooseDialogParam.visible = true;
      }
    },


    formDataValidate () {
      let result = true;
      this.$refs.elecOrdersFormRef.validate((valid) => {
        result = valid;
      });
      return result;
    },
    /*表单(form)-提交 */
    formDataSubmit () {
      if (!this.formDataValidate()) return;
      this.formDialogParam.visible = false;
      //如有特殊的Json要求,请深Copy一份,再进行修改例如
      const postData = JSON.parse(JSON.stringify(this.formData));

      //postData.classId = this.tableParam.classId;
      //postData.imageUrl = JSON.stringify(this.formData.imageUrl);//由于MysqlJson的原因
      //postData.addFile = JSON.stringify(this.formData.addFile);//由于MysqlJson的原因
      //doNew(this.formData).then(response => {
      if (this.formDialogParam.formDataAction == 'new') {
        doNew(postData).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'modify') {
        doModify(postData).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
      else if (this.formDialogParam.formDataAction == 'refunds') {
        const data = new FormData()
        data.append('id', this.formData.id);
        data.append('refund', this.formData.refund);
        data.append('reason', this.formData.reason);
        doRefunds(data).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }

    },
    formChooseSubmit () {
      this.formChooseDialogParam.visible = false;
      if (this.formChooseDialogParam.formChooseAction == 'delete') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doDelete(data).then(response => {
          if (response.data.success) {
            this.tableParam.key = '';
            this.tableParam.page = 1;
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
      else if (this.formChooseDialogParam.formChooseAction == 'close') {
        const selectNum = this.tableMultipleSelection.length;
        let canId = [];
        for (let i = 0; i < selectNum; i++) {
          if (!this.tableMultipleSelection[i].isPublic) {
            canId.push(this.tableMultipleSelection[i].id);
          }
        }
        const data = new FormData()
        data.append('id', canId);
        doClose(data).then(response => {
          if (response.data.success) {
            this.notifySuccess(response.data.msg);
            this.tableDateRefresh();
          }
          else {
            this.notifyWarning(response.data.msg);
            this.tableDateRefresh();
          }
        })
      }
    },
    /*-------------------------------------------------表格事件 -----------------------------------------------*/
    //表格-多选事件,当checkBox被选中,改变已选的值
    tableSelectionChange (val) {
      this.tableMultipleSelection = val;
    }
    ,
    /*表格-行选,点击事件 */
    tableRowClick (row) {
      //this.formData = row;
      this.formData = JSON.parse(JSON.stringify(row));//主要是为了不想改了就生效到table上
      this.$refs.elecOrdersTableRef.clearSelection(); //清除选中
      this.$refs.elecOrdersTableRef.toggleRowSelection(row); //设定选中
    },
    /*表格-改变每页显示多少条,直接重置到第一页*/
    tableSizeChange (val) {
      if (val != this.tableParam.limit) {
        this.tableParam.limit = val
        this.tableParam.page = 1;
        this.tableDateRefresh();
      }
    },
    /*表格-改变第几页*/
    tableCurrentChange (val) {
      if (val != this.tableParam.page) {
        this.tableParam.page = val;
        this.tableDateRefresh();
      }

    },
    /*表格-搜索关键词,由于改变才会重搜,所以不用在意 */
    tableChangeSearchKey (searchKey) {
      this.tableParam.key = searchKey;
      this.tableParam.page = 1;
      this.tableDateRefresh();

    },
    /*表格-置亮搜索关键词 */
    tableLightByKey (val) {
      // 不区分大小写  const Reg = new RegExp(searchData, 'i');
      // 全局替换  const Reg = new RegExp(searchData, 'g');
      if (this.tableParam.key) {
        const Reg = new RegExp(this.tableParam.key, 'ig');
        if (val) {
          // 注意 这里推荐使用正则占位符$& 不使用${searchData}  因为当这里使用正则表达式（i）不区分大小写时，如果你的文本是大写，搜索的关键字是小写，匹配结果会替换掉大写的文本
          // const res = val.replace(Reg, `<span style="background-color: yellow;color:#ffffff">${searchData}</span>`);
          const res = val.replace(Reg, `<span style="background-color: #50bfff;color:#ffffff">$&</span>`);
          return res;
        }
      }
      else {
        return val;
      }
    },
    /*表格-数据重置 */
    tableDateRefresh () {
      getTableData(this.tableParam).then((jsonData) => {
        this.tableData = jsonData.data.data;
        this.tableParam.count = jsonData.data.count;
        this.tableParam.page = jsonData.data.page;
        this.tableParam.limit = jsonData.data.limit;
      })
    },

  },
  /*监听Id的变化,如果变化了就重新去取数据 */
  watch: {},
  filters: {},
  //生命周期 - 挂载完成（可以访问DOM元素）
  mounted () {
    this.direcParam = JSON.parse(store.getters.getActiveCus());
    this.formRawData = JSON.parse(JSON.stringify(this.formData)); //深拷贝一份原始数据到formRawData
    this.tableDateRefresh();
  }
}
</script>
