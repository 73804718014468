<template>
  <el-card class="box-card">
    <div slot="header" class="clearfix">
      <span><i class="el-icon-key" style="color:#67C23A"></i> 授权证书
        <i class="el-icon-check" v-if="cerInfo.very" style="color:#67C23A">正常</i>
        <el-tooltip class="item" effect="light" :content="cerInfo.veryMsg" placement="top" v-else>
          <i class="el-icon-close" style="color:#F56C6C">失效</i>
        </el-tooltip>
      </span>
      <el-upload ref="cerUpload" action="" :http-request="cerUploadSubmit" :before-upload="cerSubmitBefore" :on-error="notifyError" :show-file-list="false"
        style="float: right; padding: 0;margin: 0px;">
        <el-button type="text" style="padding: 0 3px;">更换</el-button>
      </el-upload>
    </div>
    <div class="text item">
      <el-form label-width="80px" size="mini">
        <el-row style="padding:0px;">
          <el-col :span="12"><el-form-item label="证书ID">{{cerInfo.cerCode}}</el-form-item></el-col>
          <el-col :span="12"><el-link type="primary" @click="keyDialogVisible=true" :underline="false">查看</el-link></el-col>
        </el-row>
        <el-form-item label="授权">{{cerInfo.cerInfo.ST}}-{{cerInfo.cerInfo.L}} {{cerInfo.cerInfo.CN}}</el-form-item>
        <el-form-item label="联系">{{cerInfo.cerInfo.TelephoneNumber}}&nbsp;&nbsp;&nbsp;&nbsp;E-mail:{{cerInfo.cerInfo.E}}</el-form-item>
        <el-row style="padding:0px;">
          <el-col :span="12">
            <el-form-item label="有效期至">{{cerInfo.cerInfo.endDate}}</el-form-item>
          </el-col>
          <el-col :span="12">
            <el-link type="primary" :underline="false" v-if="cerInfo.cerInfo.day>0"> 剩余{{cerInfo.cerInfo.day}}天</el-link>
            <el-link type="danger" :underline="false" v-else> 超过{{cerInfo.cerInfo.day}}天</el-link>
          </el-col>
        </el-row>
      </el-form>
    </div>
    <el-dialog v-dialogDrag title="证书信息" :visible.sync="keyDialogVisible" width="30%">
      <el-input type="textarea" style="margin-top:10px;border:0px;" resize="none" rows="10" v-model="cerInfo.cerInfo.key" readonly=""></el-input>
    </el-dialog>
  </el-card>
</template>
 <script> 
import { getCer, postCerFile } from '@/api/wbkj/Setting.js';
export default {
  data () {
    return {
      keyDialogVisible: false,
      cerInfo: { cerInfo: { ST: '' } },
    }
  },
  methods: {
    /*上传cer文件类型判断,默认doc,docx,pdf是都可以上传*/
    cerSubmitBefore (file) {
      const fileType = file.name.substring(file.name.lastIndexOf('.'))
      if ('.cer'.indexOf(fileType) == -1) {  //允许的文件类型请自己修改,但请注意,是带.的
        this.notifyWarning('上传失败,请上传.cer文件')
        return false;
      }
      else {
        return true;
      }
    },
    cerUploadSubmit (param) {
      const cerFileData = new FormData()
      cerFileData.append('file', param.file);
      postCerFile(cerFileData).then(response => {
        if (response.data.success) {
          this.cerInfo = response.data.data;
          param.onSuccess();  // 上传成功的图片会显示绿色的对勾
        }
        else {
          param.onError(response.data.msg);//会去调用出错的方法
        }
      }).catch(error => {
        param.onError(error); //会去调用出错的解决办法
      })
    },
  },
  mounted () {
    getCer().then(response => {
      if (response.data.success) {
        this.cerInfo = response.data.data;
      }
      else {
        this.notifyError(response.data.msg);
      }
    })
  },
  watch: {

  }
}
</script>