<template>
  <div>

    <el-table :data="param" height="400" border :cell-style="{height:'24px',padding:0,margin:0}" size="mini">
      <el-table-column prop="code" label="代码" width="60">
      </el-table-column>
      <el-table-column prop="name" label="名称" width="200">
        <template slot-scope="scope">
          <el-link :underline="false">
            <el-tooltip class="item" placement="top">
              <div slot="content" style="line-height:20px;">
                代码:{{scope.row.code}}<br />字节:{{scope.row.num}}<br />位置:{{scope.row.place}}<br />默认值:{{scope.row.default}}<br />
                -----------------------以下说明-----------------------<br />
                <span v-html="formatTooltipContent(scope.row.note)"></span>
              </div>
              <span><i class="el-icon-info" style="color:#409EFF"></i> {{ scope.row.name }} </span>
            </el-tooltip>
          </el-link>
        </template>
      </el-table-column>

      <el-table-column prop="num" label="字节" width="60">
      </el-table-column>
      <el-table-column prop="place" label="位置" width="60"></el-table-column>
      <el-table-column prop="default" label="默认值"></el-table-column>
      <el-table-column prop="allow" label="配置" width="60">
        <template slot-scope="scope">
          <span style="padding-left: 4px;" v-if="scope.row.allow">
            <el-link :underline="false">
              <el-tooltip class="item" effect="light" content="允许配置其参数,并测试运行" placement="top-start">
                <i class="el-icon-check" style="font-size:16px;color: #409EFF;font-weight: 400;"></i>
              </el-tooltip>
            </el-link>
          </span>
          <span style="padding-left: 4px;" v-else>
            <el-link :underline="false">
              <el-tooltip class="item" effect="light" content="禁止配置,仅文档查看" placement="top-start">
                <i class="el-icon-close" style="font-size:16px;color: #ef0000;font-weight: 400;"></i>
              </el-tooltip>
            </el-link>
          </span>
        </template>
      </el-table-column>
      <el-table-column label="操作" width="100">
        <template slot="header">
          <el-button type="success" icon="el-icon-plus" size="mini" @click="paramNew()">新建</el-button>
        </template>
        <template slot-scope="scope">
          <el-button @click="paramEdit(scope.row)" type="text" size="small">修改</el-button>
          <el-button @click="paramDelete(scope.row)" type="text" size="small">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <el-dialog v-dialogDrag :visible.sync="paramDialogParam.visible" :width="paramDialogParam.width" :close-on-click-modal='false' append-to-body>
      <template slot="title">
        <div style="font-size:14px;">{{paramDialogParam.title}}</div>
      </template>
      <el-form :model="paramData" label-width="80px" size="small" ref="paramForm" :rules="paramDataRules">
        <!-- 这里修改表单项 -->
        <el-row style="padding-bottom:0;">
          <el-col :span="18">
            <el-form-item label="名称" prop="name">
              <el-input v-model="paramData.name" placeholder="请输入名称" id="name"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="6">
            <el-form-item label="配置" prop="allow">
              <el-checkbox v-model="paramData.allow">允许</el-checkbox>
            </el-form-item>
          </el-col>
        </el-row>
        <el-row style="padding-bottom:0;">
          <el-col :span="4">
            <el-form-item label="代码" prop="code">
              <el-input v-model="paramData.code" style="width:100%;" placeholder="请输入代码" id="code"></el-input>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="字节" prop="num">
              <el-input-number v-model="paramData.num" style="width:100%;" controls-position="right" :precision="0" :step="1" :min="0" :max="255"
                @change="numChange" placeholder="字节" id="num"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="5">
            <el-form-item label="位置" prop="place">
              <el-input-number v-model="paramData.place" style="width:100%;" controls-position="right" :precision="0" :step="1" :min="1" :max="255"
                placeholder="位置"></el-input-number>
            </el-form-item>
          </el-col>
          <el-col :span="10">
            <el-form-item label="默认值" prop="default">
              <el-input v-model="paramData.default" placeholder="请输入默认值" id="default"></el-input>
            </el-form-item>
          </el-col>
        </el-row>
        <el-form-item label="说明" prop="note">
          <el-input type="textarea" :rows="4" resize="none" placeholder="请输入数据格式说明" v-model="paramData.note">
          </el-input>
        </el-form-item>
        <el-form-item label="注意">
          <span>代码为00时,长度不作注入,为其它数值时,注入1个字节</span>
        </el-form-item>

        <!-- 这里修改表单项 -->
      </el-form>
      <span slot="footer">
        <el-button @click="paramDialogParam.visible = false">取 消</el-button>
        <el-button @click="paramPush()" type="primary">确 定</el-button>
      </span>
    </el-dialog>
  </div>
</template>
<script>
import { required } from "@/utils/formRules" //校验规则,如有特殊要求请自己去修改填写,请删除不使用的,但不删除也不影响
export default {
  props: { jsonArray: { type: Array, required: true } },
  data () {
    return {
      param: this.jsonArray,
      /*-------------------------------------------------json param 参数-----------------------------------------------*/
      paramDialogParam: {
        visible: false,
        title: '新建',
        width: '50%',
        top: '15vh',
      },
      /*--json 字段参数--*/
      paramRawData: {
        key: '',
        name: '',
        code: '',
        num: 1,
        default: '00',
        place: 1,
        allow: true,
        note: '',
      },
      paramData: {
        key: '',
        name: '',
        code: '',
        num: 1,
        default: '00',
        place: 1,
        allow: true,
        note: '',
      },
      paramDataRules:
      {
        name: [required],
        num: [required],
        place: [required],
        note: [required],
      },
    };
  },
  methods: {
    /*-------------------------------------------------json param 方法-----------------------------------------------*/
    /**
     * 随机取数
     * @param {*} a
     * @param {*} b
     */
    cusRandom (a, b) {
      var n = Math.round(Math.random() * (a - b) + b);
      return n;
    },
    numChange (value) {
      let temp = "00"
      for (let i = 1; i < value; i++) {
        temp = temp + ' 00';
      }
      this.paramData.default = temp;

    },
    paramNew () {
      this.paramData = JSON.parse(JSON.stringify(this.paramRawData))//重置
      let strData = "";
      //如果觉得12个数太少也可以多放点，将i<12修改即可
      for (var i = 0; i < 12; i++) {
        let num = this.cusRandom(0, 9);                        //数字
        let upper = String.fromCharCode(this.cusRandom(65, 90));     //大写字母
        let lower = String.fromCharCode(this.cusRandom(97, 122));    //小写字母
        strData = strData + num + upper + lower;                  //将所有结果放进strData中
      }
      let str = "";
      for (let i = 0; i < 12; i++) {
        str += strData[this.cusRandom(0, strData.length - 1)];         //在strData里面随机抽取四个数
      }
      this.paramData.key = str; //增加一个UID的标识为了修改
      this.paramDialogParam.title = "新建";
      this.paramDialogParam.visible = true;
    },
    paramPush () {
      this.$refs.paramForm.validate((valid) => {
        if (valid) {
          let index = this.param.findIndex((item) => item.key === this.paramData.key);
          if (index > -1)//表示找到了
          {
            this.param.splice(index, 1, this.paramData);
          }
          else {
            this.param.push(this.paramData);//插到最后面
          }
          this.param.sort((a, b) => a.place - b.place); //排序
          this.paramDialogParam.visible = false;//不显示
          this.$emit('success', this.param);
        }
        else {
          this.paramDialogParam.visible = true;//不显示
        }


      });
    },
    paramDelete (key) {
      let index = this.param.indexOf(key);//查出文件在哪
      // eslint-disable-next-line vue/no-mutating-props
      this.param.splice(index, 1);
      this.$emit('success', this.param);
    },
    paramEdit (key) {
      let index = this.param.indexOf(key);//查出文件在哪
      this.paramData = this.param[index];
      this.paramDialogParam.title = "修改";
      this.paramDialogParam.visible = true;
    },
  },
  filters: {},
  watch: {
    jsonArray: function () {
      this.param = this.jsonArray;
    }
  },
  mounted () {
  },
}
</script>
