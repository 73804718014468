import { httpForm, httpJson, httpFile } from '@/utils/adminRequest'
//定义一个Employee接口,数据表接口

//得到公司下的所有部门信息
export const getAllDep = (tableParam) => {
    return httpJson.get('/departs/allByCmp', { 'params': tableParam })
}
//得到公司下的所有部门信息
export const getAllRole = () => {
    return httpJson.get('/role/allRole')
}

export const getTableData = (tableParam) => {
    return httpJson.get('/employee/list', { 'params': tableParam })
}
export const getInfo = (param) => {
    return httpJson.get('/employee/info', { 'params': param })
}
export const doNew = (formData) => {
    return httpForm.post('/employee/new', formData)
}
export const doModify = (formData) => {
    return httpForm.post('/employee/modify', formData)
}
export const doDelete = (formData) => {
    return httpForm.post('/employee/delete', formData)
}
export const postFile = (fileData, config) => {
    return httpFile.post('/upload/file', fileData, config)
}
export const postImg = (fileData, config) => {
    return httpFile.post('/upload/img', fileData, config)
}


